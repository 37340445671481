import axios from 'axios';
import Vue from 'vue';
import endpoints from '@/js/endpoints';
import GeneralTemplate from '@/js/vuexTemplates/general';
import { getModuleProps } from '@/js/utils';

const fileUploadState = {
  shouldRefresh: {},
};

const fileUploadGetters = {};

const mutations = {
  updateShouldRefresh(state, { dataType, payload }) {
    Vue.set(state.shouldRefresh, dataType, payload);
  },
};

const actions = {
  async uploadFiles({
    dispatch, rootState, rootGetters, commit,
  }, { files, dataType }) {
    const auth = rootGetters['auth/headerAuthorization'];
    const uploadPromises = [];
    const tooLargeFilesNames = [];
    for (const file of files) {
      if (file.size >= (50 * 1024 * 1024)) {
        tooLargeFilesNames.push(file.name);
        continue;
      }
      const formData = new FormData();
      formData.append('file', file);
      formData.append('name', file.name);
      formData.append('data_source', rootState.dataSource.details.id);
      formData.append('data_type', dataType);
      uploadPromises.push(axios.post(endpoints.fileUpload, formData, auth));
    }
    if (tooLargeFilesNames.length > 0) {
      const msg = `Files over 50Mb are not allowed. Will not upload: ${tooLargeFilesNames.join(', ')}`;
      dispatch('sidebar/showWarning', {
        title: 'Too large file',
        text: msg,
      }, { root: true });
    }
    await Promise.all(uploadPromises);
    commit('updateShouldRefresh', { dataType: 'fileUpload', payload: true });
    await axios.post(`${endpoints.fileUpload}process/`, {}, auth);
  },
  async convertToArticles({ dispatch, rootGetters, rootState }, { id2data }) {
    const auth = rootGetters['auth/headerAuthorization'];
    const resp = await axios.post(`${endpoints.fileUpload}convert-to-articles/`, {
      id2data,
    }, auth);
    await dispatch('fetchItems', { params: { data_source: rootState.dataSource.details.id } });
    return resp.status === 200;
  },
};

const templates = [
  new GeneralTemplate(endpoints.fileUpload, 'fileUpload'),
];
export default {
  namespaced: true,
  ...getModuleProps({
    state: fileUploadState, getters: fileUploadGetters, mutations, actions,
  }, templates),
};
