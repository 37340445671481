<template>
  <div>
    <h4 class="mt-3">
      Crawling
    </h4>
    <edit-key-value
      class="my-3"
      key-prop="Crawling rules"
      description="Crawl links satisfying these rules"
      :value-prop="localData.crawlingRule"
      :min-key-width="keyWidth"
      :options="sitemapOptions"
      type="select"
      @input="(x) => $emit('update', { prop: 'crawlingRule', value: x })"
    />
    <edit-key-value
      v-if="localData.crawlingRule === SITEMAP_URL_SITEMAP"
      class="my-3"
      key-prop="Sitemap urls"
      description="The crawler will only visit links that are found on the sitemap urls, and stay in the same top-level domains as the sitemap urls."
      :value-prop="null"
      :min-key-width="keyWidth"
      type=""
    >
      <template #inputcontent>
        <b-list-group class="w-100 urls-list r-25-right border">
          <string-list
            class="string-list"
            fixed-input
            placeholder="Add url..."
            :strings="localData.sitemapUrls"
            @change="updateSitemapUrls"
          />
        </b-list-group>
      </template>
    </edit-key-value>
    <h4 class="mt-3">
      Scraping
    </h4>
    <b-link v-b-modal.scraper-documentation>
      <h6>
        Click here to see scraping documentation
      </h6>
    </b-link>
    <edit-key-value
      class="my-3"
      key-prop="Scraping urls"
      description="The URLs used to determine if the scraper should search for articles on a given page"
      :value-prop="null"
      :min-key-width="keyWidth"
      type=""
    >
      <template #inputcontent>
        <b-list-group class="w-100 urls-list r-25-right border">
          <string-list
            class="string-list"
            fixed-input
            placeholder="Add url..."
            :strings="localData.startUrls"
            @change="updateStartUrls"
          />
        </b-list-group>
      </template>
    </edit-key-value>
    <edit-key-value
      class="my-3"
      key-prop="Scraping inclusion rules"
      description="Look for articles in links that satisfy these rules"
      :value-prop="localData.scrapingRule"
      :min-key-width="keyWidth"
      :options="prefixOptions"
      type="select"
      @input="(x) => $emit('update', { prop: 'scrapingRule', value: x })"
    />
    <edit-key-value
      v-if="localData.scrapingRule === PREFIX_URL_REGEX"
      class="my-3"
      key-prop="Regular expression"
      description="Look for articles in links that satisfy any of these regular expressions"
      :value-prop="null"
      :min-key-width="keyWidth"
      type=""
    >
      <template #inputcontent>
        <b-list-group class="w-100 urls-list r-25-right border">
          <string-list
            class="string-list"
            fixed-input
            :validate="true"
            :validations="['regex']"
            placeholder="Add regular expression..."
            :strings="localData.includeRegexes"
            @change="updateIncludeRegexes"
          />
        </b-list-group>
      </template>
    </edit-key-value>
    <edit-key-value
      class="my-3"
      key-prop="Scraping exclusion rules"
      description="Do not look for articles in links that satisfy any of these regular expressions"
      :value-prop="null"
      :min-key-width="keyWidth"
      type=""
    >
      <template #inputcontent>
        <b-list-group class="w-100 urls-list r-25-right border">
          <string-list
            class="string-list"
            fixed-input
            :validate="true"
            :validations="['regex']"
            placeholder="Add regular expression..."
            :strings="localData.excludeRegexes"
            @change="updateExcludeRegexes"
          />
        </b-list-group>
      </template>
    </edit-key-value>
    <b-button
      v-b-modal.scraper-test
      v-b-tooltip.hover.noninteractive.viewport
      variant="primary"
      :disabled="localData.startUrls.length === 0 || unsavedChanges"
      class="mr-2"
      @click="scrapeTest({ webScraperId: localData.id, testCrawler: true })"
    >
      Test scraper
    </b-button>
    <small v-if="unsavedChanges" class="text-muted d-block">Save changes before testing scraper.</small>
    <b-modal
      id="scraper-test"
      size="xl"
      no-close-on-backdrop
      hide-footer
      scrollable
      @hidden="cleanUpTest"
    >
      <template #modal-title>
        <b-spinner v-if="isScrapingSingle && !testError" small />
        Test scraping:
        <b-link :href="testCurrentUrl" target="blank" style="word-break: break-all;">
          {{ testCurrentUrl }}
        </b-link>
      </template>
      <b-row v-if="scrapingResultSingle">
        <b-col v-if="testError" cols="12" class="mb-2">
          Error: {{ testError }}
        </b-col>
        <b-col>
          <b-table
            :items="testUrls"
            :fields="testFields"
            hover
            class="mb-0"
            @row-clicked="rowClicked"
          >
            <template #cell(level)="row">
              <b-badge style="width: 80px;">
                <span
                  v-if="row.item.level === 'scrape'"
                  v-b-tooltip.hover.left.noninteractive.viewport="'Currently handling URL'"
                >Scraping</span>
                <span
                  v-else-if="row.item.level === 'scraped' && getTestArticles(row.item).length === 0"
                  v-b-tooltip.hover.left.noninteractive.viewport="'URL handled, but no articles found'"
                >Scraped</span>
                <span
                  v-else-if="row.item.level === 'scraped' && getTestArticles(row.item).length > 0"
                  v-b-tooltip.hover.left.noninteractive.viewport="'URL handled, found articles'"
                >Populated</span>
                <span
                  v-else
                  v-b-tooltip.hover.left.noninteractive.viewport="'URL visited, but not scraped'"
                >Crawled</span>
              </b-badge>
            </template>

            <template #row-details="row">
              <ScrapeItemDetails :item="row.item" />
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-modal>
    <h5 class="mt-3">
      Commands
    </h5>
    <CommandsComponent
      :scraper-id="localData.id"
      :command-ids="localData.definition.commandIds"
      :commands="localData.definition.commands"
      :allowed-danger-commands="[ADD_ARTICLE]"
    />
    <documentation-modal />
    <botscript-modal />
  </div>
</template>
<script>

import { mapState, mapGetters, mapActions } from 'vuex';
import update from 'immutability-helper';
import { cloneDeep } from 'lodash';
import StringList from 'supwiz/components/StringList.vue';
import EditKeyValue from 'supwiz/components/EditKeyValue.vue';
import CommandsComponent from '@/components/WebScraper/SeleniumCommands/CommandsComponent.vue';
import {
  PREFIX_URL_TYPES, SITEMAP_URL_TYPES, DEFAULT_LINK_EXTRACTOR, ADD_ARTICLE,
}
  from '@/js/selenium_constants';
import DocumentationModal from '@/components/WebScraper/DocumentationModal.vue';
import BotscriptModal from '@/components/WebScraper/BotscriptModal.vue';
import ScrapeItemDetails from '@/components/WebScraper/ScrapeItemDetails.vue';

export default {
  name: 'WebScraping',
  components: {
    CommandsComponent,
    StringList,
    DocumentationModal,
    EditKeyValue,
    BotscriptModal,
    ScrapeItemDetails,
  },
  props: {
    localData: {
      type: Object,
      required: true,
    },
    unsavedChanges: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      testFields: [
        { key: 'url', label: 'URL', tdClass: 'cursor-pointer' },
        {
          key: 'level', label: 'Status', tdClass: 'level-col cursor-pointer', thClass: 'level-col',
        },
      ],
      rule: {
        ...cloneDeep(DEFAULT_LINK_EXTRACTOR),
        uid: this.$route.params.extractorId,
      },
      PREFIX_URL_TYPES,
      PREFIX_URL_REGEX: PREFIX_URL_TYPES.REGEX,
      SITEMAP_URL_SITEMAP: SITEMAP_URL_TYPES.SITEMAP,
      ADD_ARTICLE,
      rowDetails: {},
    };
  },
  computed: {
    ...mapState('article', ['isScrapingSingle']),
    ...mapGetters('article', ['scrapingResultSingle']),
    testUrls() {
      // eslint-disable-next-line no-underscore-dangle
      return ((this.scrapingResultSingle || {})._url_visits || [])
        .map((e) => ({ ...e, _showDetails: !!this.rowDetails[e.url] }));
    },
    testCurrentUrl() {
      const urls = this.testUrls;
      if (urls == null || urls.length === 0) {
        return '';
      }
      return urls[urls.length - 1].url;
    },
    testError() {
      // eslint-disable-next-line no-underscore-dangle
      return (this.scrapingResultSingle || {})._error;
    },
    keyWidth() {
      return 200;
    },
    prefixOptions() {
      return [
        {
          value: PREFIX_URL_TYPES.TOPLEVEL,
          text: 'Article links must have same top level domain as a crawling url',
        },
        {
          value: PREFIX_URL_TYPES.CHILDREN,
          text: 'Article links must be a child of a start url',
        },
        {
          value: PREFIX_URL_TYPES.REGEX,
          text: 'Article links must match some regular expression',
        },
      ];
    },
    sitemapOptions() {
      return [
        {
          value: SITEMAP_URL_TYPES.TOPLEVEL,
          text: 'Crawling links must have same top level domain as a scraping url',
        },
        {
          value: SITEMAP_URL_TYPES.SITEMAP,
          text: 'Crawling links must present on a sitemap',
        },
      ];
    },
  },
  methods: {
    ...mapActions('article', ['scrapeTest', 'abortScrapingTest']),
    async cleanUpTest() {
      await this.abortScrapingTest();
    },
    rowClicked(item) {
      this.$set(this.rowDetails, item.url, !this.rowDetails[item.url]);
    },
    updateStartUrls(startUrls) {
      Object.assign(this.localData, update(this.localData, { startUrls }));
    },
    updateSitemapUrls(sitemapUrls) {
      Object.assign(this.localData, update(this.localData, { sitemapUrls }));
    },
    updateIncludeRegexes(includeRegexes) {
      Object.assign(this.localData, update(this.localData, { includeRegexes }));
    },
    updateExcludeRegexes(excludeRegexes) {
      Object.assign(this.localData, update(this.localData, { excludeRegexes }));
    },
    showKeywordsModal() {
      this.$bvModal.show('keywords-modal');
    },
    getTestArticles(item) {
      // eslint-disable-next-line no-underscore-dangle
      return item.state?._test_articles || [];
    },
  },
};
</script>
<style scoped>
.string-list{
  max-height: 195px;
  overflow-y: auto;
}
::v-deep .b-table-details:hover{
  background-color:white !important;
}
::v-deep .level-col{
  width: 100px;
}

</style>
