<template>
  <b-row
    v-if="isFetchingDetails"
    class="h-100 align-items-center"
  >
    <b-col class="text-center">
      <b-spinner
        style="width: 5rem; height: 5rem;"
      />
    </b-col>
  </b-row>
  <b-row v-else-if="objectNotFound">
    <b-col>
      <h1>Search engine version not found</h1>
      <p>Requested version could not be found.</p>
    </b-col>
  </b-row>
  <div v-else>
    <b-card
      title="Search Engine Version"
      class="r-75 mb-3"
      body-class="p-3"
    >
      <div>
        <display-key-value
          class="my-3"
          key-prop="Created time"
          :value-prop="getTime"
          :min-key-width="keyWidth"
        />
        <display-key-value
          class="my-3"
          key-prop="Type"
          :value-prop="getType()"
          :min-key-width="keyWidth"
        />
        <edit-key-value
          v-if="showClassicSearch"
          class="my-3"
          key-prop="Classic search weight"
          description="How much weight classic search is given compared to
                 AI predictions"
          :value-prop="localClassicSeach"
          :min-key-width="keyWidth"
          type="range"
          range-tooltip-format="percentage"
          @input="(x) => localClassicSeach = x"
        />

        <b-row no-gutters class="cursor-pointer mt-3" @click="showDetails = !showDetails">
          <b-col cols="auto" class="pr-2 my-auto h5 pt-1">
            <font-awesome-icon :icon="showDetails ? 'angle-down' : 'angle-right'" />
          </b-col>
          <b-col class="my-auto h4" cols="auto">
            Details
          </b-col>
        </b-row>

        <b-collapse :visible="showDetails" style="min-height: 480px;">
          <b-row>
            <b-col>
              <b-table
                v-if="isTrainable"
                class="mt-5"
                :items="items"
                :fields="tableFields"
                :caption="caption"
                hover
              />
              <transformer-details
                v-else
                class="mt-4"
                :details="rankerInstanceDetails"
              />
            </b-col>
            <b-col
              v-if="showChart"
              ref="trainingChart"
              cols="6"
              class="text-center"
              style="max-height: 450px;"
            >
              <b-row>
                <b-col class="text-right">
                  <b-form-radio-group
                    v-model="metricToPlot"
                    :options="metricOptions"
                    buttons
                    size="sm"
                    class="ml-auto pr-3"
                    button-variant="primary"
                  />
                </b-col>
              </b-row>
              <typed-chart
                :chart-data="chartData"
                :options="chartOptions"
                chart-type="line"
              />
            </b-col>
          </b-row>
        </b-collapse>

        <div class="mt-3">
          <b-button variant="primary" :disabled="!unsavedChanges" @click="updateClassicSearch">
            Update
          </b-button>
          <b-button
            variant="danger"
            class="ml-2"
            :disabled="rankerInstanceDetails.active"
            @click="deleteRankerInstanceLocal"
          >
            Delete
          </b-button>
          <b-button
            v-if="rankerInstanceDetails.task && rankerInstanceDetails.task.status === 'pending'"
            variant="warning"
            class="ml-2"
            @click="stopTraining"
          >
            {{
              rankerInstanceDetails.task.aborted || localIsAborted
                ? 'Stopping training...' : 'Stop training'
            }}
          </b-button>
        </div>
      </div>
    </b-card>
    <pipeline-build-single v-if="getBuildId" :build-id="getBuildId" />
  </div>
</template>

<script>

import {
  mapState, mapActions, mapGetters, mapMutations,
} from 'vuex';
import { taskFormatter, percentageFormatter } from 'supwiz/util/formatters';
import EditKeyValue from 'supwiz/components/EditKeyValue.vue';
import DisplayKeyValue from 'supwiz/components/DisplayKeyValue.vue';
import TransformerDetails from '@/components/Ranker/transformerDetails.vue';
import TypedChart from '@/components/typedChart.vue';
import { rankerType2pretty, languageModelName2pretty } from '@/js/constants';
import PipelineBuildSingle from '@/pages/Ranker/PipelineBuild/Single.vue';

const metrics = ['eval_accuracy', 'proxy_accuracy', 'eval_loss'];

const metricNames = {
  eval_loss: 'Loss',
  eval_accuracy: 'Accuracy',
  proxy_accuracy: 'Accuracy (proxy)',
};

function languageModel2pretty(languageModelName) {
  let name = languageModelName;
  const pos = languageModelName.search('/');
  if (pos > 0) {
    const lang = languageModelName.slice(0, pos);
    if (Object.keys(languageModelName2pretty).includes(lang)) {
      name = languageModelName2pretty[lang];
    }
  }
  return name;
}

export default {
  name: 'RankerInstanceSingle',
  components: {
    TypedChart,
    TransformerDetails,
    DisplayKeyValue,
    PipelineBuildSingle,
    EditKeyValue,
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchRankerInstanceDetails({ id: to.params.rankerInstanceId }).finally(() => {
      this.initInterval();
    });
    next();
  },
  beforeRouteLeave(to, from, next) {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    next();
  },
  data() {
    return {
      keyWidth: 200,
      showDetails: false,
      intervalId: null,
      metricToPlot: 'eval_accuracy',
      localIsAborted: false,
      fields: [
        { key: 'classicSearchIndex', label: 'Classic search included', formatter: (x) => x !== null },
        {
          key: 'accuracy',
          label: 'Accuracy',
          formatter: (x) => `${percentageFormatter(x)}${(this.accuracyIsProxy && x != null) ? '*' : ''}`,
        },
        { key: 'task.error_message', label: 'Error Message' },
        { key: 'active', label: 'Active' },
        { key: 'task', label: 'Task Status', formatter: taskFormatter },
      ],
      tableFields: [
        {
          key: 'key', tdClass: 'font-weight-bold', thClass: 'd-none',
        },
        { key: 'value', thClass: 'd-none' },
      ],
      localClassicSeach: null,
    };
  },
  computed: {
    ...mapState('auth', ['globalConfig']),
    ...mapState('rankerInstance', ['isFetchingDetails']),
    ...mapState('rankerInstance', { rankerInstances: 'items' }),
    ...mapState('rankerInstance', { rankerInstanceDetails: 'details' }),
    ...mapState('dataSource', { dataSources: 'items' }),
    ...mapGetters('languageModel', { getLanguageModelName: 'itemIdToName' }),
    ...mapGetters('pipeline', ['isArticlePrediction']),
    ...mapState('ranker', { rankerDetails: 'details' }),
    ...mapGetters('ranker', ['isTrainable']),
    getTime() {
      return new Date(this.rankerInstanceDetails.createdTime).toLocaleString('en-GB');
    },
    showClassicSearch() {
      return this.isArticlePrediction(this.rankerDetails.pipeline.type)
      && this.rankerInstanceDetails.config.use_classic_search
      && !this.isClassicalSearchOnlyRanker;
    },
    unsavedChanges() {
      return this.localClassicSeach !== this.rankerInstanceDetails.classicSearchWeight;
    },
    getBuildId() {
      return this.rankerInstanceDetails?.config?.build;
    },
    accuracyIsProxy() {
      return (this.rankerInstanceDetails.trainLog || {}).acc_is_proxy || false;
    },
    caption() {
      if (this.accuracyIsProxy) {
        return '*Accuracy is proximal due to low number of labelled data. Cannot be compared to non-proximal accuracies';
      }
      return '';
    },
    objectNotFound() {
      return Object.keys(this.rankerInstanceDetails).length === 0;
    },
    showChart() {
      return this.isTrainable && !this.isClassicalSearchOnlyRanker;
    },
    isClassicalSearchOnlyRanker() {
      return this.rankerInstanceDetails.config.type === 'classic';
    },
    items() {
      const items = [];
      for (const fieldObj of this.fields) {
        let val;
        const keys = fieldObj.key.split('.');
        val = this.rankerInstanceDetails[keys[0]];
        if (fieldObj.key === 'task.error_message' && !val) {
          continue;
        }
        for (const key of keys.slice(1)) {
          val = val[key];
        }
        if (fieldObj.formatter) {
          val = fieldObj.formatter(val);
        }
        if (this.isClassicalSearchOnlyRanker && fieldObj.key === 'classicSearchIndex') {
          continue;
        }
        items.push({ key: fieldObj.label, value: val });
      }
      return items;
    },
    metricOptions() {
      const options = [];
      for (const key of metrics) {
        if (key in this.rankerInstanceDetails.trainLog) {
          options.push({ text: metricNames[key], value: key });
        }
      }
      return options;
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          y: {
            scaleLabel: {
              display: true,
              labelString: this.datasets[this.metricToPlot].label,
            },
            beginAtZero: true,
            max: this.metricToPlot === 'eval_accuracy' ? 1 : null,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      };
    },
    datasets() {
      return {
        eval_loss: {
          label: 'Loss',
          fill: false,
          borderColor: '#8e5ea2',
        },
        eval_accuracy: {
          label: 'Accuracy',
          fill: false,
          borderColor: '#3e95cd',
        },
        proxy_accuracy: {
          label: 'Accuracy (proxy)',
          fill: false,
          borderColor: '#3e95cd',
        },
      };
    },
    chartData() {
      const dataset = this.datasets[this.metricToPlot];
      const vals = this.rankerInstanceDetails.trainLog[this.metricToPlot];
      if (vals) {
        dataset.data = vals.map((x) => Number(x.toFixed(3)));
      } else {
        dataset.data = null;
      }
      return { datasets: [dataset], labels: this.rankerInstanceDetails.trainLog.step };
    },
  },
  watch: {
    isFetchingDetails(n) {
      if (!n) {
        this.localClassicSeach = this.rankerInstanceDetails.classicSearchWeight;
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
    this.setRankerInstanceDetails({});
  },
  created() {
    if (Object.values(this.rankerInstanceDetails).length === 0) {
      this.fetchRankerInstanceDetails({ id: this.$route.params.rankerInstanceId }).finally(() => {
        this.initInterval();
      });
    }
  },
  methods: {
    ...mapActions('rankerInstance', { deleteRankerInstance: 'deleteItem' }),
    ...mapActions('rankerInstance', ['refreshRankerInstance']),
    ...mapActions('rankerInstance', { patchRankerInstance: 'patchItem' }),
    ...mapActions('rankerInstance', { fetchRankerInstanceDetails: 'fetchItemDetails' }),
    ...mapMutations('rankerInstance', { setRankerInstanceDetails: 'setItemDetails' }),
    ...mapActions('task', ['abortTask']),
    updateClassicSearch() {
      const data = {
        id: this.rankerInstanceDetails.id,
        classic_search_weight: this.localClassicSeach,
        active: this.rankerInstanceDetails.active,
      };
      this.patchRankerInstance(data);
    },
    async deleteRankerInstanceLocal() {
      const modalText = 'Are you sure that you want to delete this search engine version?';
      const modalOptions = { okTitle: 'Delete', okVariant: 'danger' };
      if (await this.$bvModal.msgBoxConfirm(modalText, modalOptions)) {
        this.deleteRankerInstance(
          {
            item: this.rankerInstanceDetails,
            fetchParams: { ranker: this.rankerDetails.id },
          },
        );
        this.$router.push({ name: 'ranker-versions-overview' });
      }
    },
    async stopTraining() {
      const modalText = 'Are you sure that you want to stop the training?';
      const modalOptions = { okTitle: 'Stop training', okVariant: 'warning' };
      if (await this.$bvModal.msgBoxConfirm(modalText, modalOptions)) {
        this.abortTask(this.rankerInstanceDetails.task.celery_id);
        this.localIsAborted = true;
      }
    },
    initInterval() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      this.intervalId = setInterval(this.update, 5000);
    },
    update() {
      this.refreshRankerInstance();
    },
    getType() {
      const rawType = this.rankerInstanceDetails.config.type;
      const type = rankerType2pretty[rawType] || rawType;
      const rawLanguage = this.rankerInstanceDetails.config.language_model;
      const language = languageModel2pretty(this.getLanguageModelName(rawLanguage));
      return `${type} (${language})`;
    },
  },
};
</script>
