import axios from 'axios';
import GeneralTemplate from '@/js/vuexTemplates/general';
import SharedTemplate from '@/js/vuexTemplates/shared';
import endpoints from '@/js/endpoints';
import { getModuleProps } from '@/js/utils';

const taskState = {
  activeTasks: [],
  pendingTasks: [],
};
const getters = {
  getTasksOfType: (state) => (type) => ({
    activeTasks: state.activeTasks.filter((e) => e.type === type),
    pendingTasks: state.pendingTasks.filter((e) => e.type === type),
  }),
  getTasksOfTypes: (state) => (types) => ({
    activeTasks: state.activeTasks.filter((e) => types.includes(e.type)),
    pendingTasks: state.pendingTasks.filter((e) => types.includes(e.type)),
  }),
};
const mutations = {
  setTasks(state, data) {
    state.pendingTasks = data.pending;
    state.activeTasks = data.active;
  },
};

const actions = {
  async abortTask({ rootGetters }, celeryId) {
    const endpoint = `${endpoints.task}${celeryId}/abort/`;
    const resp = await axios.post(endpoint, {}, rootGetters['auth/headerAuthorization']);
    return resp.data.success;
  },
  async fetchTaskList({ rootGetters, commit, dispatch }, refresh = false) {
    if (rootGetters['auth/isArticleViewerOrNone']) {
      console.log('No tasks');
      return;
    }
    try {
      const { data } = await axios.get(`${endpoints.task}active-tasks/`, { ...rootGetters['auth/headerAuthorization'], params: { refresh } });
      commit('setTasks', data);
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to fetch running tasks',
        text: error.message,
        variant: 'danger',
      }, { root: true });
    }
  },
  async refreshSingleTaskProgress({ rootGetters, state, dispatch }, taskId) {
    try {
      const resp = await axios.get(`${endpoints.task}${taskId}/`, { params: {}, ...rootGetters['auth/headerAuthorization'] });
      return resp;
    } catch (error) {
      if (error.response.status === 404) {
        await dispatch('fetchTaskList', true);
        const pendingTaskIds = state.pendingTasks.map((e) => e.id);
        if (pendingTaskIds.includes(taskId)) {
          return { status: 200, data: { status: 'pending' } };
        }
      }
      return error;
    }
  },
};

const templates = [
  new GeneralTemplate(endpoints.task, 'task'),
  new SharedTemplate('task'),
];
export default {
  namespaced: true,
  ...getModuleProps({
    state: taskState,
    getters,
    mutations,
    actions,
  }, templates),
};
