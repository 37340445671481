<template>
  <div>
    <b-form-group class="mb-2">
      <b-list-group
        class="bg-light r-25 conditions"
      >
        <b-list-group-item
          v-for="(condition, index) in conditions"
          :key="condition.id"
          class="conditions__condition d-flex align-items-center m-1 r-25"
        >
          <span
            v-b-tooltip.viewport.noninteractive
            title="Click to edit"
            class="w-100"
          >
            <div
              class="flex-grow-1 pr-1 cursor-pointer"
              tabindex="0"
              @click="prepareConditionEdit(condition)"
              @keyup.enter="prepareConditionEdit(condition)"
            >
              <span class="condition__key">{{ condition.text }}</span>
              <span>{{ condition.negate ? ' not in' : ' in' }} {{ fieldDisplay(condition) }}</span>
              <span v-if="condition.case_sensitive"> (case)</span>
              <span v-if="condition.exact_match"> (exact)</span>
              <span v-if="condition.is_regex"> (re)</span>
            </div>
          </span>
          <b-button
            v-b-tooltip.viewport.noninteractive
            title="Remove"
            type="button"
            class="btn-invis condition__btn ease-in-out"
            @click="removeCondition(index)"
          >
            <font-awesome-icon
              icon="times"
              style="transform: scale(1.25)"
            />
          </b-button>
        </b-list-group-item>
        <span
          v-if="conditions.length === 0"
          class="ml-1 p-1 d-block"
        >
          <em>No Text Conditions</em>
        </span>
      </b-list-group>
    </b-form-group>
    <b-button
      block
      variant="primary"
      @click="$bvModal.show(`createTextCondition${id}`)"
    >
      Add Condition
    </b-button>
    <b-modal
      :id="`createTextCondition${id}`"
      ref="createTextConditionModal"
      title="Condition"
      :ok-disabled="$v.currentCondition.$invalid"
      @ok="addCondition"
      @hide="resetCondition"
    >
      <b-row>
        <b-col>
          <b-form-group
            label="Pattern"
            label-for="textPattern"
          >
            <b-form-input
              id="textPattern"
              v-model="currentCondition.text"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            class="mb-0"
            label="Regex"
            label-for="isRegex"
          >
            <b-checkbox
              id="isRegex"
              v-model="currentCondition.is_regex"
              switch
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            class="mb-0"
            label="Negate"
            label-for="antiPattern"
          >
            <b-checkbox
              id="antiPattern"
              v-model="currentCondition.negate"
              switch
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            class="mb-0"
            label="Case sensitive"
            label-for="caseSensitive"
          >
            <b-checkbox
              id="caseSensitive"
              v-model="currentCondition.case_sensitive"
              switch
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="!currentCondition.is_regex"
        >
          <b-form-group
            class="mb-0"
            label="Exact match"
            label-for="exactMatch"
          >
            <b-checkbox
              id="exactMatch"
              v-model="currentCondition.exact_match"
              switch
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="allowMetaData && currentCondition.meta_field != null" class="mt-2">
        <b-col>
          <b-form-group label="Input field" class="mb-0">
            <meta-data-selector
              :default-value="currentCondition.meta_field"
              @metaDataFieldSelected="setMetaDataField"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col>
          Test Example
          <b-form-group
            :label="fieldDisplay(currentCondition)"
            label-for="testInput"
          >
            <b-form-textarea
              id="testInput"
              v-model="currentCondition.query"
            />
          </b-form-group>
          <b-button
            block
            :variant="testConditionButtonVariant"
            @click="handleTestCondition"
          >
            {{ testConditionButtonText }}
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import Vue from 'vue';
import { validationMixin } from 'vuelidate';
import {
  required, minLength,
} from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';
import MetaDataSelector from '@/components/Ranker/MetaDataSelector.vue';

export default {
  name: 'TextCondition',
  components: {
    MetaDataSelector,
  },
  mixins: [
    validationMixin,
  ],
  props: {
    id: {
      type: String,
      default: '',
    },
    conditions: {
      required: true,
      type: Array,
    },
    queryDisplay: {
      required: false,
      type: String,
      default: 'query',
    },
    allowMetaData: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentCondition: this.newCondition(),
      conditionIndex: -1,
    };
  },
  computed: {
    ...mapGetters('metaDataField', { metaDataFields: 'items' }),
    testConditionButtonVariant() {
      switch (this.currentCondition.applies) {
        case true:
          return 'success';
        case false:
        case 'invalid regex':
          return 'danger';
        default:
          return 'secondary';
      }
    },
    testConditionButtonText() {
      switch (this.currentCondition.applies) {
        case true:
          return 'Test Condition - Applies';
        case false:
          return 'Test Condition - Does Not Apply';
        case 'invalid regex':
          return 'Test Condition - Invalid Regex';
        default:
          return 'Test Condition';
      }
    },
  },
  created() {
    this.fetchMetaDataFields();
  },
  methods: {
    ...mapActions('customRule', ['testCondition']),
    ...mapActions('metaDataField', { fetchMetaDataFields: 'fetchItems' }),
    newCondition() {
      return {
        text: '',
        case_sensitive: false,
        is_regex: false,
        negate: false,
        exact_match: false,
        query: '',
        meta_field: null,
      };
    },
    handleTestCondition() {
      const testData = this.currentCondition;
      if (testData.query == null) {
        testData.query = '';
      }
      this.testCondition(testData).then(({ data }) => {
        Vue.set(this.currentCondition, 'applies', data);
      });
    },
    resetCondition() {
      this.currentCondition = this.newCondition();
      this.conditionIndex = -1;
    },
    prepareConditionEdit(condition) {
      this.currentCondition = cloneDeep(condition);
      this.conditionIndex = this.conditions.indexOf(condition);
      this.$bvModal.show('createTextCondition');
    },
    addCondition() {
      if (this.currentCondition.is_regex) {
        this.currentCondition.exact_match = false;
      }
      if (this.conditionIndex !== -1) {
        this.$emit('editTextCondition', { index: this.conditionIndex, condition: this.currentCondition });
      } else {
        this.$emit('addTextCondition', this.currentCondition);
      }
    },
    removeCondition(index) {
      this.$emit('removeTextCondition', index);
    },
    setMetaDataField(value) {
      this.currentCondition.meta_field = value;
    },
    fieldDisplay(condition) {
      if (this.allowMetaData && condition.meta_field != null) {
        return `${this.metaDataFields[condition.meta_field].key} (meta)`;
      }
      return this.queryDisplay;
    },
  },
  validations() {
    return {
      currentCondition: {
        text: { required, minLength: minLength(1) },
      },
    };
  },

};
</script>

<style scoped>
.conditions__condition .condition__key,
.conditions__condition .condition__val {
  background-color: #f2f2f2;
  padding: 0.125rem .25rem;
  border-radius: .25rem;
  position: relative;
  overflow-wrap: anywhere;
  word-break: break-all;
  word-wrap: break-word;
}
.conditions .conditions__condition .condition__btn {
    height: 20px;
    min-width: 20px;
    width: 20px;
    max-width: 20px;
    line-height: 100%;
    padding: 0;
    margin: 0;
    color: rgba(0,0,0,0.25);
    transition-property: color;
}
.conditions .conditions__condition:hover .condition__btn {
    color: rgba(0,0,0,0.5)
}
.conditions {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 500px;
}
.conditions .conditions__condition {
  border-radius: .25rem;
  line-height: 175%;
}
</style>
