import dataSourceSpecs from '@/js/dataSourceSpecs';
import { accessLevels } from '@/js/constants';

const dataSourcePages = [
  {
    display: 'Config',
    linkName: 'data-source-config',
    icon: 'cog',
  },
  {
    display: 'Articles',
    linkName: 'article',
    icon: 'book',
    matchOn: ['articles-overview', 'articles-single'],
  },
  {
    display: 'Chats',
    linkName: 'chat',
    icon: 'comments',
    matchOn: ['chats-overview', 'chats-single-details', 'chat-activities-single'],
  },
  {
    display: 'Tickets',
    linkName: 'ticket',
    icon: 'ticket-alt',
    matchOn: ['tickets-overview', 'tickets-single'],
  },
  {
    display: 'Queries',
    linkName: 'query',
    icon: 'comment',
    matchOn: ['queries-overview', 'queries-single'],
  },
  {
    display: 'Questions',
    linkName: 'question',
    icon: 'comment',
    matchOn: ['questions-overview', 'questions-single'],
  },
  {
    display: 'Tags',
    linkName: 'tags',
    icon: 'grip-vertical',
    matchOn: ['tags-overview', 'tags-single-details', 'tag-values-single'],
  },
];

const rankerPages = [
  {
    display: 'Statistics',
    linkName: 'ranker-statistics',
    icon: 'chart-column',
    requiredRole: 'observer',
  },
  {
    display: 'Collected Data',
    linkName: 'ranker-collected-data',
    icon: 'folder-open',
    requiredRole: 'trainer',
    matchOn: ['ranker-query', 'feedback',
      'ranker-queries-overview', 'ranker-queries-single',
    ],
    children: [
      {
        display: 'Queries',
        linkName: 'ranker-query',
        icon: 'comment',
        matchOn: ['ranker-queries-overview', 'ranker-queries-single'],
      },
    ],
  },
  {
    display: 'Configuration',
    linkName: 'ranker-config',
    icon: 'cog',
    requiredRole: 'regular',
  },
  {
    display: 'Widget',
    linkName: 'widget',
    icon: 'window-maximize',
    requiredRole: 'regular',
  },
  {
    display: 'Versions',
    linkName: 'ranker-versions-overview',
    icon: 'code-branch',
    requiredRole: 'trainer',
    matchOn: ['ranker-versions-overview', 'ranker-versions-single', 'pipeline-build-details', 'datapoints-single'],
  },
  {
    display: 'Test & Stage',
    linkName: 'ranker-testing',
    icon: 'fa-rocket',
    requiredRole: 'observer',
  },

];

const configPages = [
  {
    display: 'Anonymization',
    linkName: 'global-anonymization',
    icon: 'user-secret',
  },
  {
    display: 'Task Overview',
    linkName: 'task-overview',
    icon: 'table-list',
  },
  {
    display: 'User Management',
    linkName: 'user-management',
    icon: 'user',
  },
];

const sidebarState = {
  showSidebar: false,
  pageLoading: false,
};

const sidebarGetters = {
  pages: (state, getters, rootState) => {
    const route = rootState.route;
    if (route.fullPath.startsWith('/ranker') && route.params.rankerId) {
      const copy = JSON.parse(JSON.stringify(rankerPages));
      if (rootState.ranker.details?.pipeline?.type === 'article_prediction') {
        copy[1].children.push({
          display: 'Feedback',
          linkName: 'feedback',
          icon: 'clipboard-list',
        });
        copy.splice(2, 0, {
          display: 'Custom Rules',
          linkName: 'custom-rule',
          icon: 'less-than-equal',
          requiredRole: 'regular',
        });
      }
      const userAccessLevel = accessLevels[rootState.auth.user.group] || 0;
      return copy.filter((x) => userAccessLevel >= (accessLevels[x.requiredRole] || 5));
    }
    if (route.fullPath.startsWith('/data-source') && route.params.dataSourceId) {
      const dataSource = rootState.dataSource.items[route.params.dataSourceId];
      const spec = dataSource ? dataSourceSpecs[dataSource.type] : [];
      return dataSourcePages.filter((page) => {
        if (['article', 'chat', 'ticket', 'query'].includes(page.linkName)) {
          return spec.includes(page.linkName);
        }
        return true;
      });
    }
    if (route.fullPath.startsWith('/global-config')) {
      return configPages;
    }
    return [];
  },
  loggedIn: (state, getters, rootState) => rootState.auth.loggedIn,
};

const mutations = {
  setShowSidebar(state, payload) {
    state.showSidebar = payload;
  },
  setLoading(state, payload) {
    state.pageLoading = payload;
  },
};

const actions = {
  showWarning({ dispatch }, { title, text, variant = 'danger' }) {
    dispatch('templateStore/templateSendNotification', {
      title,
      text,
      variant,
      toast: true,
    }, { root: true });
  },
};

export default {
  namespaced: true,
  state: sidebarState,
  getters: sidebarGetters,
  mutations,
  actions,
};
