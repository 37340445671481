import axios from 'axios';
import Vue from 'vue';
import { sortBy } from 'lodash';
import { objToCamel, objToSnake, camelCaseToText } from '@/js/utils';
import { dataSourceTypeToEndpoint } from '@/js/constants';
import endpoints from '@/js/endpoints';

const paginatedDataTypes = ['article', 'chat', 'ticket', 'query', 'question', 'datapoint', 'pipelineBuild', 'rankerInstance', 'task'];

function extractErrorMessage(error) {
  if (error.request == null) {
    return null;
  }
  if (error.request.status === 400) {
    try {
      const errors = JSON.parse(error.request.response);
      return errors.join(', ');
    } catch (e) {
      return null;
    }
  }
  return null;
}

export default class {
  constructor(endpoint, dataType) {
    this.state = {
      isFetching: false,
      items: {},
      pagination: {
        count: null,
        page: 1,
        perPage: 10,
      },
      isFetchingDetails: false,
      details: {},
      isDisabling: false,
    };

    this.getters = {
      busy: (state) => state.isFetching || state.isFetchingDetails,
      isFetching: (state) => state.isFetching,
      items: (state) => state.items,
      detailsId: (state) => state.details.id,
      page: (state) => state.pagination.page,
      details: (state) => state.details,
    };

    this.mutations = {
      setIsFetching(state, payload) {
        state.isFetching = payload;
      },
      setItems(state, items) {
        const itemsList = sortBy(items, 'name');
        const itemsDict = {};
        for (const obj of itemsList) {
          itemsDict[obj.id] = objToCamel(obj);
        }
        state.items = itemsDict;
      },
      updateItem(state, { item }) {
        Vue.set(state.items, item.id, item);
      },
      updatePagination(state, payload) {
        state.pagination = Object.assign(state.pagination, payload);
      },
      resetPagination(state) {
        state.pagination = {
          count: null,
          page: 1,
          perPage: 10,
        };
      },
      setIsFetchingDetails(state, payload) {
        state.isFetchingDetails = payload;
      },
      setItemDetails(state, data) {
        state.details = data ? objToCamel(data) : data;
      },
      setIsDisabling(state, value) {
        state.isDisabling = value;
      },
    };

    this.actions = {
      async fetchItems({
        commit, dispatch, rootGetters,
      }, { params, refreshing } = { params: {}, refreshing: false }) {
        if (!refreshing) {
          commit('setIsFetching', true);
        }
        try {
          const request = { ...rootGetters['auth/headerAuthorization'] };

          // allow using array as parameter value for GET
          const searchParams = new URLSearchParams();
          Object.entries(params || {}).forEach(([k, v]) => {
            if (![null, undefined].includes(v)) {
              if (Array.isArray(v)) {
                v.forEach((val) => { searchParams.append(k, val); });
              } else {
                searchParams.append(k, v);
              }
            }
          });
          request.params = searchParams;
          const { data } = await axios.get(endpoint, request);
          if (paginatedDataTypes.includes(dataType)) {
            const { results, count } = data;
            commit('updatePagination', { count });
            commit('setItems', results);
            commit('setIsFetching', false);
            return results;
          }
          commit('setItems', data);
          commit('setIsFetching', false);
          return data;
        } catch (error) {
          if (error?.response?.status === 404) {
            Vue.prototype.goTo404Page();
          } else if (error?.response?.status === 403 && rootGetters['auth/isArticleViewerOrNone']) {
            //
          } else if (error?.response?.status === 401) {
            Vue.prototype.goToLoginPage();
          } else {
            const formattedDataType = dataType === 'query' ? 'querie' : dataType;
            dispatch('sidebar/showWarning', {
              title: `Failed to fetch ${camelCaseToText(formattedDataType)}s`,
              text: error.message,
            }, { root: true });
            throw error;
          }
        } finally {
          commit('setIsFetching', false);
        }
        return [];
      },
      async fetchItemDetails({
        commit, dispatch, rootGetters,
      }, { id, refreshing }) {
        if (!refreshing) {
          commit('setIsFetchingDetails', true);
        }
        try {
          const auth = rootGetters['auth/headerAuthorization'];
          const { data } = await axios.get(`${endpoint}${id}/`, auth);
          commit('setItemDetails', objToCamel(data));
        } catch (error) {
          dispatch('sidebar/showWarning', {
            title: `Failed to fetch ${camelCaseToText(dataType)} details`,
            text: error.message,
          }, { root: true });
          commit('setIsFetchingDetails', false);
          if (error?.response?.status === 404) {
            Vue.prototype.goTo404Page();
          } else if (error?.response?.status === 401) {
            Vue.prototype.goToLoginPage();
          }
          throw error;
        }
        commit('setIsFetchingDetails', false);
      },
      async addItem({ dispatch, rootGetters }, { newItem, fetchParams = {} }) {
        const auth = rootGetters['auth/headerAuthorization'];
        let itemData;
        let addEndpoint;
        if (dataType === 'dataSource') {
          const { type, ...data } = objToSnake(newItem);
          itemData = data;
          addEndpoint = dataSourceTypeToEndpoint[type];
        } else {
          itemData = objToSnake(newItem);
          addEndpoint = endpoint;
        }
        try {
          const { data: addedItem } = await axios.post(addEndpoint, itemData, auth);
          if ('id' in addedItem || addedItem.length) {
            await dispatch('fetchItems', { params: fetchParams });
            return addedItem;
          }
          dispatch('sidebar/showWarning', {
            title: 'Unexpected Response',
            text: `Got unexpected response when adding ${camelCaseToText(dataType)}`,
          }, { root: true });
          return false;
        } catch (error) {
          dispatch('sidebar/showWarning', {
            title: `Failed to add ${camelCaseToText(dataType)}`,
            text: error.message,
          }, { root: true });
          throw error;
        }
      },
      async deleteItem({ dispatch, rootGetters }, { item, fetchParams = {} }) {
        const auth = rootGetters['auth/headerAuthorization'];
        try {
          await axios.delete(`${endpoint}${item.id}/`, auth);
          dispatch('fetchItems', { params: fetchParams });
          return true;
        } catch (error) {
          dispatch('sidebar/showWarning', {
            title: `Failed to delete ${camelCaseToText(dataType)}`,
            text: extractErrorMessage(error) || error.message,
          }, { root: true });
          return false;
        }
      },
      async patchItem({ dispatch, rootGetters }, item) {
        const auth = rootGetters['auth/headerAuthorization'];
        const { ...data } = objToSnake(item);
        try {
          let updateEndpoint;
          if (dataType === 'dataSource') {
            updateEndpoint = dataSourceTypeToEndpoint[item.type];
          } else {
            updateEndpoint = endpoint;
          }
          await axios.patch(`${updateEndpoint}${item.id}/`, data, auth);
          if (dataType === 'customRule') {
            dispatch('fetchItems', { params: { ranker_id: item.ranker_id } });
          } else {
            dispatch('fetchItemDetails', { id: item.id, refreshing: false });
          }
          return true;
        } catch (error) {
          dispatch('sidebar/showWarning', {
            title: `Failed to update ${camelCaseToText(dataType)}`,
            text: error.message,
          }, { root: true });
          return false;
        }
      },
      async toggleDisabled({
        state, rootGetters, dispatch, commit,
      }, itemId) {
        try {
          commit('setIsDisabling', true);
          const request = { ...rootGetters['auth/headerAuthorization'] };
          const disabled = itemId ? state.items[itemId].disabled : state.details.disabled;
          const id = itemId || state.details.id;
          const params = { disabled: !disabled };
          if (endpoint === endpoints.article && !params.disabled) {
            // For articles, if you are enabling an article, you should
            // also lift the auto_disabling. If you are disabling, then
            // leave auto_disabling alone
            params.auto_disabled = false;
          }
          await axios.patch(`${endpoint}${id}/`, params, request);
          if (itemId) {
            await dispatch('fetchItems', { params: {}, refreshing: true });
          } else {
            await dispatch('fetchItemDetails', { id, refreshing: true });
          }
        } catch (error) {
          dispatch('sidebar/showWarning', {
            title: 'Failed to mark as ignored',
            text: error.message,
          }, { root: true });
          throw error;
        } finally {
          commit('setIsDisabling', false);
        }
      },
    };
  }
}
