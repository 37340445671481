<template>
  <base-activity
    ref="baseActivity"
    :scraper-id="scraperId"
    :command-id="commandId"
    :indent="indent"
    bs-variant="success"
    v-on="$listeners"
  >
    <template #icon>
      <font-awesome-icon icon="book" />
    </template>
    <template #header>
      AddArticle({{ articleArgs }})
    </template>
    <b-row>
      <b-col cols="10">
        <b-form-group
          :label="viaFileLink ? 'File link variable' : 'Text variable'"
          :description="`The content of ${viaFileLink ? 'the file linked in' : ''} this variable will be the main body of the article`"
        >
          <b-form-input
            :value="command.source_var"
            type="text"
            @input="value => $root.$emit('update-scraper', { commandId, prop: 'source_var', value })"
          />
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group
          label="Is file link"
        >
          <b-form-checkbox
            v-model="sourceVarIsFileLink"
            switch
            size="lg"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h6>
          This action supports botscript.
          <b-link v-b-modal.botscript-documentation-modal>
            Click here to see botscript documentation
          </b-link>
        </h6>
      </b-col>
      <b-col
        class="text-right"
      >
        <b-button @click="advancedVisible = !advancedVisible">
          {{ advancedVisible ? 'Hide' : 'Show' }} Advanced Options
        </b-button>
      </b-col>
    </b-row>
    <b-collapse id="advanced-config" v-model="advancedVisible">
      <b-form-group
        label="Title variable"
        description="Leave empty to use HTML page title"
      >
        <b-form-input
          :value="command.title_var"
          type="text"
          @input="value => $root.$emit('update-scraper', { commandId, prop: 'title_var', value })"
        />
      </b-form-group>
      <b-form-group
        label="External ID variable"
        description="Leave empty to use page URL"
      >
        <b-form-input
          :value="command.external_id_var"
          type="text"
          @input="value => $root.$emit('update-scraper', { commandId, prop: 'external_id_var', value })"
        />
      </b-form-group>
      <hr>
      <b-form-group
        label="Capture url query"
        description="Toggle if the query portion of the url should be included when generating the url.
        A url query is usually indicated by a '?' like this: 'https://example.com/subpage?query=123"
      >
        <b-form-checkbox
          v-model="captureUrlQuery"
          switch
        />
      </b-form-group>
      <b-form-group
        label="Deep link variable"
        description="Allows you to specify section on page with a deep link. The variable
        should have a value like '#link-to-section'"
      >
        <b-form-input
          :value="command.deep_link_var"
          type="text"
          @input="value => $root.$emit('update-scraper', { commandId, prop: 'deep_link_var', value })"
        />
      </b-form-group>
      <hr>
      <b-form-group
        label="Metadata tags"
        description="Add tags to the article. Tags can be used to filter which articles are used
        in a search engine"
      >
        <b-table
          :fields="fields"
          :items="command.tags"
          small
          show-empty
        >
          <template #cell(field)="data">
            <b-form-input
              :value="data.item.field"
              type="text"
              @input="value => updateTags(data.index, data.field.key, value)"
            />
          </template>
          <template #cell(value)="data">
            <b-form-input
              :value="data.item.value"
              type="text"
              @input="value => updateTags(data.index, data.field.key, value)"
            />
          </template>
          <template #cell(item_actions)="data">
            <b-button
              variant="danger"
              @click="deleteTag(data.index)"
            >
              <font-awesome-icon icon="trash-alt" />
            </b-button>
          </template>
        </b-table>
        <b-button
          variant="primary"
          class="mb-2"
          @click="createTag"
        >
          <font-awesome-icon icon="plus" />
        </b-button>
      </b-form-group>
    </b-collapse>
  </base-activity>
</template>

<script>
import BaseActivity from '@/components/WebScraper/SeleniumCommands/BaseCommand.vue';

export default {
  name: 'AddArticleCommand',
  components: { BaseActivity },
  props: {
    scraperId: {
      type: Number,
      required: true,
    },
    commandId: {
      type: String,
      required: true,
    },
    command: {
      type: Object,
      required: true,
    },
    indent: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      advancedVisible: false,
      fields: [
        { key: 'field', label: 'Key' },
        { key: 'value', label: 'Value (BotScript enabled)' },
        { key: 'item_actions', label: '' },
      ],
    };
  },
  computed: {
    viaFileLink() {
      return this.command.source_var_is_file_link;
    },
    articleArgs() {
      let s = this.command.source_var;
      for (const [name, key] of [['title', 'title_var'], ['external_id', 'external_id_var'], ['deep_link', 'deep_link_var']]) {
        const val = this.command[key];
        if (val) {
          s = `${s}, ${name}=${val}`;
        }
      }
      return s;
    },
    sourceVarIsFileLink: {
      get() {
        return this.command.source_var_is_file_link;
      },
      set(value) {
        this.$root.$emit('update-scraper', { commandId: this.commandId, prop: 'source_var_is_file_link', value });
      },
    },
    captureUrlQuery: {
      get() {
        return this.command.capture_url_query;
      },
      set(value) {
        this.$root.$emit('update-scraper', { commandId: this.commandId, prop: 'capture_url_query', value });
      },
    },
  },
  methods: {
    createTag() {
      const currentTags = this.command.tags || [];
      currentTags.push({ field: '', value: '' });
      this.$root.$emit('update-scraper', { commandId: this.commandId, prop: 'tags', value: currentTags });
    },
    updateTags(index, type, newVal) {
      const currentTags = this.command.tags;
      currentTags[index][type] = newVal;
      this.$root.$emit('update-scraper', { commandId: this.commandId, prop: 'tags', value: currentTags });
    },
    deleteTag(index) {
      const currentTags = this.command.tags;
      currentTags.splice(index, 1);
      this.$root.$emit('update-scraper', { commandId: this.commandId, prop: 'tags', value: currentTags });
    },
  },
};
</script>
