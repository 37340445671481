const articlePatternField = {
  key: 'articleLinkPattern',
  label: 'Article Link Pattern',
  description: 'Generate article links from the article id (e.g., "company.com/article?id={id}")',
  type: 'input',
};

const autoImportField = {
  key: 'syncAutomatically',
  label: 'Sync Automatically',
  description: 'Run imports every hour',
  type: 'checkbox',
};

const extraDataSourceFields = {
  uploaddatasource: [
    articlePatternField,
  ],
  zendeskdatasource: [
    {
      key: 'endpointUrl',
      label: 'Endpoint URL',
      description: 'URL to Zendesk API. Example: https://somename.zendesk.com/',
      type: 'input',
    },
    {
      key: 'token',
      label: 'Zopim Token',
      description: 'Zopim API (chats) token (leave empty to omit in update)',
      placeholder: '********',
      type: 'password',
    },
    {
      key: 'zendeskToken',
      label: 'Zendesk Token',
      description: 'Zendesk API (tickets) token (leave empty to omit in update)',
      placeholder: '********',
      type: 'password',
    },
    {
      key: 'botIds',
      label: 'Bot IDs',
      description: 'Agent IDs of the bots in Zendesk. These should be defined prior to importing.',
      type: '',
    },
    autoImportField,
    {
      key: 'syncFrom',
      label: 'Sync From',
      description: 'The Data Source will automatically sync data from this date and forward',
      type: 'date',
    },
    articlePatternField,
  ],
  onenotedatasource: [
    {
      key: 'tenantId',
      label: 'Tenant ID',
      description: 'ID of the Azure tenant to sync OneNote data from.',
      type: 'input',
    },
    {
      key: 'clientId',
      label: 'Client ID',
      description: 'ID of the SupSearch app registration in Azure.',
      type: 'input',
    },
    {
      key: 'clientSecret',
      label: 'Client secret',
      description: 'Secret of the SupSearch app registration in Azure.',
      type: 'password',
    },
    {
      key: 'ownerType',
      label: 'Owner Type',
      description: 'The type of resource to sync OneNote data from.',
      type: 'select',
      options: [{ text: 'Group', value: 'group' }, { text: 'Site', value: 'site' }],
    },
    {
      key: 'ownerId',
      label: 'Owner ID',
      description: 'The ID of the resource to sync OneNote data from.',
      type: 'input',
    },
    {
      key: 'useDelegatedAccess',
      label: 'Use delegated access',
      description: 'Delegated access allows SupSearch to act on behalf of a user',
      type: 'checkbox',
    },
  ],
  sharepointdatasource: [
    {
      key: 'tenantId',
      label: 'Tenant ID',
      description: 'ID of the Azure tenant to sync Sharepoint data from.',
      type: 'input',
    },
    {
      key: 'clientId',
      label: 'Client ID',
      description: 'ID of the SupSearch app registration in Azure.',
      type: 'input',
    },
    {
      key: 'clientSecret',
      label: 'Client secret',
      description: 'Secret of the SupSearch app registration in Azure.',
      type: 'password',
    },
    {
      key: 'siteId',
      label: 'Site ID',
      description: 'The ID of the Sharepoint site to sync data from.',
      type: 'input',
    },
    {
      key: 'useDelegatedAccess',
      label: 'Use delegated access',
      description: 'Delegated access allows SupSearch to act on behalf of a user',
      type: 'checkbox',
    },
  ],
  topdeskdatasource: [
    {
      key: 'endpointUrl',
      label: 'Endpoint URL',
      description: 'URL to TopDesk instance. Example: https://somename.topdesk.net',
      type: 'input',
    },
    {
      key: 'username',
      label: 'Username',
      description: 'TopDesk username',
      type: 'input',
    },
    {
      key: 'password',
      label: 'Password',
      description: 'TopDesk API password (leave empty to omit in update)',
      placeholder: '********',
      type: 'password',
    },
  ],
  supchatdatasource: [
    {
      key: 'endpointUrl',
      label: 'Endpoint URL',
      description: 'URL to SupChat API. Example: https://somename.supchat.supwizapp.com',
      type: 'input',
    },
    {
      key: 'username',
      label: 'Username',
      description: 'Supchat username',
      type: 'input',
    },
    {
      key: 'password',
      label: 'Password',
      description: 'Supchat password (leave empty to omit in update)',
      placeholder: '********',
      type: 'password',
    },
    autoImportField,
    {
      key: 'syncFrom',
      label: 'Sync From',
      description: 'The Data Source will automatically sync data from this date and forward',
      type: 'date',
    },
  ],
  puzzeldatasource: [
    {
      key: 'customerKey',
      label: 'Customer key',
      description: 'Puzzel customer number',
      type: 'input',
    },
    {
      key: 'username',
      label: 'Username',
      description: 'Puzzel username',
      type: 'input',
    },
    {
      key: 'password',
      label: 'Password',
      description: 'Puzzel password (leave empty to omit in update)',
      placeholder: '********',
      type: 'password',
    },
    {
      key: 'botIds',
      label: 'Bot IDs',
      description: 'Agent IDs of the bots in Puzzel. These should be defined prior to importing.',
      type: '',
    },
    autoImportField,
    {
      key: 'syncFrom',
      label: 'Sync From',
      description: 'The Data Source will automatically sync data from this date and forward',
      type: 'date',
    },
  ],
  ticketanalyzerdatasource: [
    {
      key: 'endpointUrl',
      label: 'Endpoint URL',
      description: 'URL to TicketAnalyzer API. Example: https://somename.ticketanalyzer.supwizapp.com',
      type: 'input',

    },
    {
      key: 'username',
      label: 'Username',
      description: 'TicketAnalyzer username',
      type: 'input',
    },
    {
      key: 'password',
      label: 'Password',
      description: 'TicketAnalyzer password (leave empty to omit in update)',
      placeholder: '********',
      type: 'password',
    },
    {
      key: 'fieldsToSync',
      label: 'Ticket Analyzer Fields',
      description: 'Ticket Analyzer fields that will be synchronized. No fields checked will synchronize everything.',
      type: '',
    },
    autoImportField,
    {
      key: 'syncFrom',
      label: 'Sync From',
      description: 'The Data Source will automatically sync data from this date and forward',
      type: 'date',
    },
  ],
  webscraper: [
    {
      key: 'syncAutomatically',
      label: 'Sync Automatically',
      description: 'Run scraping every night',
      type: 'checkbox',
    },
  ],
  rightanswersdatasource: [
    {
      key: 'endpointUrl',
      label: 'Endpoint URL',
      description: `URL to RightAnswers knowledge base. Example:
          https://somename.rightanswers.com/portal/api/soap?wsdl`,
      type: 'input',
    },
    {
      key: 'companyCode',
      label: 'Company Code',
      description: 'Company Code used by API',
      type: 'input',
    },
    {
      key: 'appInterface',
      label: 'App Interface',
      description: 'API app interface',
      type: 'input',
    },
    {
      key: 'username',
      label: 'Username',
      description: 'RightAnswers username',
      type: 'input',
    },
    {
      key: 'password',
      label: 'Password',
      description: 'RightAnswers password (leave empty to omit in update)',
      type: 'password',
    },
    autoImportField,
    {
      key: 'syncFrom',
      label: 'Sync From',
      description: 'The Data Source will automatically sync data from this date and forward',
      type: 'date',
    },
    articlePatternField,
  ],
  bomgardatasource: [
    {
      key: 'endpointUrl',
      label: 'Endpoint URL',
      description: 'URL to Bomgar API. Example: https://somename.bomgarcloud.com/',
      type: 'input',
    },
    autoImportField,
    {
      key: 'clientId',
      label: 'Client ID',
      type: 'input',
    },
    {
      key: 'clientSecret',
      label: 'Client Secret',
      description: 'Bomgar API user secret. (Leave empty to omit in update)',
      type: 'password',
    },
    {
      key: 'botIds',
      label: 'Bot IDs',
      description: 'Representative IDs of the bots in Bomgar. These should be defined prior to importing.',
      type: '',
    },
    {
      key: 'syncFrom',
      label: 'Sync From',
      description: 'The Data Source will automatically sync data from this date and forward',
      type: 'date',
    },
  ],
  rankerdatasource: [
    {
      key: 'ranker',
      label: 'Search Engine',
      description: 'The search engine that provides the queries of this data sources.',
      type: 'input',
    },
  ],
  servicenowdatasource: [
    {
      key: 'endpointUrl',
      label: 'Endpoint URL',
      description: 'URL to ServiceNow instance. Example: https://somename.service-now.com/api/now/table/kb_knowledge',
      type: 'input',
    },
    autoImportField,
    {
      key: 'username',
      label: 'Username',
      description: 'Username for the ServiceNow instance',
      type: 'input',
    },
    {
      key: 'password',
      label: 'Password',
      description: 'ServiceNow password (leave empty to omit in update)',
      type: 'password',
    },
    {
      key: 'textFieldName',
      label: 'Text field name',
      description: 'The field containing the text of the articles (default is "text")',
      type: 'input',
    },
    {
      key: 'titleFieldName',
      label: 'Title field name',
      description: 'The field containing the title of the articles (default is "short_description")',
      type: 'input',
    },
    {
      key: 'idFieldName',
      label: 'ID field name',
      description: 'The field containing the ID of the articles (default is "number")',
      type: 'input',
    },
    {
      key: 'fieldsToSync',
      label: 'Extra fields to sync',
      description: 'Extra fields that will be synchronized. Supports only first level values e.g. no deep-links',
      type: '',
    },
    {
      key: 'useAttachments',
      label: 'Use attachments',
      description: 'If enabled article attachments (pdf or docx) are included in sync',
      type: 'checkbox',
    },
    {
      key: 'knowledgeBaseTable',
      label: 'Knowledge base table',
      description: 'Table to extract articles from',
      type: 'input',
    },
    {
      key: 'knowledgeBaseParams',
      label: 'Knowledge base filter',
      description: 'Filter parameters applied when synchronising articles. Supply them on the form "key=value"',
      type: '',
    },
    articlePatternField,
  ],
};

export default extraDataSourceFields;
