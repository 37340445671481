<template>
  <div>
    <b-card
      title="Queries"
      class="r-75"
      body-class="p-3"
    >
      <b-row class="my-3">
        <b-col class="my-auto">
          <b-pagination
            v-model="currentPage"
            size="sm"
            class="my-auto"
            :total-rows="pagination.count"
            :per-page="pagination.perPage"
            aria-controls="queries-table"
          />
        </b-col>
      </b-row>
      <b-table
        id="queries-table"
        ref="queries-table"
        :fields="fields"
        :current-page="currentPage"
        :per-page="pagination.perPage"
        :items="itemsProvider"
        class="mb-0"
        tbody-tr-class="cursor-pointer"
        show-empty
        empty-text="There are currently no queries."
        hover
        @row-clicked="openQuery"
      >
        <template #cell(actions)="data">
          <b-button
            :disabled="isDisabling"
            :variant="data.item.disabled ? 'danger' : 'primary'"
            @click.stop="handleToggleDisabled(data.item.id)"
          >
            {{ data.item.disabled ? 'Ignored' : 'Ignore' }}
          </b-button>
        </template>
      </b-table>
    </b-card>
    <br />
    <file-upload-table v-if="isUploadType" data-type="query" />
  </div>
</template>

<script>

import {
  mapState, mapActions, mapMutations,
} from 'vuex';
import { dataSourceTypes } from '@/js/constants';
import { objToCamel } from '@/js/utils';
import FileUploadTable from '../../components/DataSource/FileUploadTable.vue';

export default {
  name: 'DataSourceQueries',
  components: { FileUploadTable },
  data() {
    return {
      fields: [
        { key: 'id', label: 'Id', tdClass: 'table-nobreak' },
        'externalId',
        { key: 'timestamp', label: 'Timestamp', formatter: (date) => new Date(date).toLocaleString('en-GB') },
        'text',
        {
          key: 'actions', label: '', tdClass: 'table-nobreak',
        },
      ],
    };
  },
  computed: {
    ...mapState('dataSource', { dataSourceDetails: 'details' }),
    ...mapState('query', { queries: 'items' }),
    ...mapState('query', ['pagination', 'isDisabling']),
    ...mapState('fileUpload', ['shouldRefresh']),
    currentPage: {
      get() {
        return this.pagination.page;
      },
      set(val) {
        this.updatePagination({ page: val });
      },
    },
    isUploadType() {
      return this.dataSourceDetails?.type === dataSourceTypes.UPLOAD.value;
    },
    shouldRefreshQueries() {
      return this.shouldRefresh.query;
    },
  },
  watch: {
    shouldRefreshQueries(newVal) {
      if (newVal) {
        this.$refs['queries-table'].$refs.table.refresh();
        this.updateShouldRefresh({ dataType: 'query', payload: false });
      }
    },
  },
  methods: {
    ...mapActions('query', {
      fetchQueries: 'fetchItems',
      toggleDisabled: 'toggleDisabled',
    }),
    ...mapMutations('query', ['updatePagination']),
    ...mapMutations('fileUpload', ['updateShouldRefresh']),
    openQuery(row) {
      if (this.$route.params.rankerId) {
        this.$router.push({ name: 'ranker-queries-single', params: { queryId: row.id } });
      } else {
        this.$router.push({ name: 'queries-single', params: { queryId: row.id } });
      }
    },
    async handleToggleDisabled(queryId) {
      await this.toggleDisabled(queryId);
      this.$refs['queries-table'].refresh();
    },
    async itemsProvider() {
      const queries = await this.fetchQueries({
        params: {
          data_source: this.dataSourceDetails.id,
          page: this.pagination.page,
        },
      });
      return Object.values(queries.map((c) => objToCamel(c)));
    },
  },
};
</script>
<style scoped>
</style>
