<template>
  <div>
    <b-card
      :title="tagging ? '' : 'Articles'"
      class="r-75"
      :body-class="tagging ? 'p-0' : 'p-3'"
    >
      <TaskStatus v-if="!tagging" :task-types="taskTypes" />
      <b-row class="my-2">
        <b-col>
          <b-pagination
            v-model="currentPage"
            size="sm"
            class="my-auto"
            :total-rows="pagination.count"
            :per-page="pagination.perPage"
            aria-controls="articles-table"
          />
        </b-col>
        <b-col cols="auto">
          <b-form-radio-group
            v-model="disabledState"
            :options="disabledOptions"
            button-variant="outline-primary"
            buttons
          />
        </b-col>
        <b-col
          v-if="!tagging && dataSourceDetails.type !== dataSourceTypes.UPLOAD.value"
          cols="auto"
        >
          <DebounceButton
            :text="`${ isWebScraper ? 'Scrape' : 'Import' } articles`"
            @click="importClick"
          />
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col>
          <b-input-group>
            <b-input-group-prepend>
              <b-dropdown :text="`Search (${getSelectedSearchFieldsText})`" toggle-class="search-btn">
                <b-dropdown-form>
                  <b-form-checkbox-group
                    v-model="selectedSearchFields"
                    :options="searchOptions"
                    stacked
                  />
                </b-dropdown-form>
              </b-dropdown>
            </b-input-group-prepend>
            <b-form-input v-model="searchKeyword" type="search" @keyup.enter="searchArticles" />
            <b-input-group-append>
              <b-button variant="primary" @click="searchArticles">
                Search
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-table
        id="articles-table"
        ref="articles-table"
        :fields="fields"
        :per-page="pagination.perPage"
        class="mb-0"
        :caption="tagging ? '' : tableCaption "
        :items="articlesArray"
        show-empty
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        tbody-tr-class="cursor-pointer"
        empty-text="There are currently no articles."
        hover
        @row-clicked="openArticle"
      >
        <template #head(select)="data">
          <b-form-checkbox
            v-b-tooltip.hover="allVisibleSelected ? 'Deselect all' : 'Select all'"
            :checked="allVisibleSelected"
            @change="toggleAll()"
          >
            {{ data.label }}
          </b-form-checkbox>
        </template>
        <template #cell(select)="row">
          <b-form-checkbox
            :checked="isTagged(row.item)"
            @change="v=>toggleArticleTag(row.item)"
          />
        </template>
        <template #cell(urlsCombined)="row">
          {{ row.item.urls.concat(row.item.urlsManual).join(', ') }}
        </template>
        <template #cell(actions)="data">
          <b-button
            block
            size="sm"
            :disabled="isDisabling"
            :variant="data.item.disabled || data.item.autoDisabled ? 'danger' : 'primary'"
            @click.stop="handleToggleDisabled(data.item.id)"
          >
            {{ ignoreText(data.item) }}
          </b-button>
        </template>
      </b-table>
    </b-card>
    <b-row v-if="tagging" no-gutters>
      <b-col />
      <b-col cols="auto mr-2">
        <b-input-group>
          <template #prepend>
            <b-input-group-text class="bg-success text-white px-3">
              {{ freshTagged.length }}
            </b-input-group-text>
          </template>
          <template #append>
            <b-input-group-text class="bg-danger text-white px-3">
              {{ freshUntagged.length }}
            </b-input-group-text>
          </template>
        </b-input-group>
      </b-col>
      <b-col cols="auto">
        <b-button
          variant="primary"
          :disabled="!freshTagged.length && !freshUntagged.length"
          @click="tagArticlesProxy()"
        >
          <b-spinner v-if="isUpdatingTags" small />
          Update
        </b-button>
      </b-col>
    </b-row>
    <template v-else>
      <br />
      <file-upload-table v-if="isUploadType " data-type="article" />
    </template>
  </div>
</template>

<script>

import {
  mapState, mapActions, mapMutations, mapGetters,
} from 'vuex';
import { textFormatter } from 'supwiz/util/formatters';
import { dataSourceTypes } from '@/js/constants';
import { objToCamel } from '@/js/utils';
import FileUploadTable from '@/components/DataSource/FileUploadTable.vue';
import TaskStatus from '@/components/TaskStatus.vue';

export default {
  name: 'DataSourceArticle',
  components: { FileUploadTable, TaskStatus },
  props: {
    tagging: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      sortBy: 'articleCreated',
      sortDesc: false,
      dataSourceTypes,
      disabledOptions: [
        { text: 'All Articles', value: 'all' },
        { text: 'Enabled Articles', value: 'enabled' },
        { text: 'Disabled Articles', value: 'disabled' },
      ],
      searchKeyword: '',
      selectedSearchFields: ['title', 'text', 'current_url'],
      searchOptions: [
        { text: 'Title', value: 'title' },
        { text: 'Text', value: 'text' },
        { text: 'Current url', value: 'current_url' },
      ],
      hasAutoDisabled: false,
      taskTypes: ['scraping', 'article_import'],
      intervalId: null,
      lastUpdated: null,
      freshTagged: [],
      freshUntagged: [],
    };
  },
  computed: {
    ...mapState('article', { articles: 'items' }),
    ...mapState('article', ['pagination', 'showDisabled', 'isDisabling', 'isUpdatingTags']),
    ...mapState('dataSource', { dataSourceDetails: 'details' }),
    ...mapState('task', ['runningTasks']),
    ...mapState('fileUpload', ['shouldRefresh']),
    ...mapGetters('task', ['getTasksOfTypes']),
    ...mapState('fieldValue', { fieldValueDetails: 'details' }),
    runningTasks() {
      return this.getTasksOfTypes(this.taskTypes).activeTasks.filter((e) => e.status === 'pending');
    },
    tableCaption() {
      let out = `Last updated: ${this.lastUpdated ? this.lastUpdated.toLocaleTimeString() : '-'}`;
      if (this.hasAutoDisabled) {
        out += '. * = article was not found in latest import and thus automatically disabled';
      }
      return out;
    },
    getSelectedSearchFieldsText() {
      return this.selectedSearchFields.map((e) => this.searchOptions
        .find((o) => o.value === e).text).join(', ');
    },
    articlesArray() {
      const articlesArray = Object.values(this.articles);
      return Object.values(articlesArray.map((c) => objToCamel(c)));
    },
    fields() {
      let fields = [
        {
          key: 'articleCreated', label: 'Created', formatter: (value) => (value ? new Date(value).toLocaleString() : '-'), tdClass: 'time-col', sortable: true,
        },
        {
          key: 'articleModified', label: 'Modified', formatter: (value) => (value ? new Date(value).toLocaleString() : '-'), tdClass: 'time-col', sortable: true,
        },
        {
          key: 'title', formatter: (value) => textFormatter(value, 100),
        },

        { key: 'currentUrl', formatter: (value) => textFormatter(value, 200) },
      ];
      if (this.tagging) {
        fields.unshift({ key: 'select', label: '', tdClass: 'select-col' });
      } else {
        fields = [
          ...fields.slice(0, 3),
          { key: 'text', formatter: (value) => textFormatter(value, 200) },
          ...fields.slice(3),
        ];
        fields.push({ key: 'actions', label: '', tdClass: 'action-col' });
      }
      return fields;
    },
    currentPage: {
      get() {
        return this.pagination.page;
      },
      set(val) {
        this.updatePagination({ page: val });
      },
    },
    disabledState: {
      get() {
        return this.showDisabled;
      },
      set(val) {
        this.setShowDisabled(val);
      },
    },
    isWebScraper() {
      if (this.dataSourceDetails.type === this.dataSourceTypes.WEBSCRAPER.value) {
        return true;
      }
      return false;
    },
    isUploadType() {
      return this.dataSourceDetails?.type === dataSourceTypes.UPLOAD.value;
    },
    shouldRefreshArticles() {
      return this.shouldRefresh.article;
    },
    getSorting() {
      return { sortBy: this.sortBy, sortDesc: this.sortDesc };
    },
    allVisibleSelected() {
      return !Object.values(this.articles).some((e) => !this.isTagged(e));
    },
  },
  watch: {
    runningTasks(n) {
      if (!this.tagging) {
        if (n.length) {
          if (!this.intervalId) {
            this.intervalId = setInterval(this.refreshData, 5000);
          }
        } else if (this.intervalId) {
          clearInterval(this.intervalId);
          this.intervalId = null;
          this.refreshData();
          this.fetchFields();
          this.fetchFieldValues();
        }
      }
    },
    shouldRefreshTickets(newVal) {
      if (newVal) {
        this.refreshData();
        this.updateShouldRefresh({ dataType: 'article', payload: false });
      }
    },
    showDisabled() {
      this.refreshData();
    },
    currentPage() {
      this.refreshData();
    },
    getSorting() {
      this.refreshData();
    },
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.currentPage = 1;
    this.disabledState = 'all';
  },
  created() {
    this.refreshData();
  },
  methods: {
    ...mapMutations('article', ['updatePagination', 'setShowDisabled']),
    ...mapActions('article', {
      importArticles: 'importItems',
      fetchArticles: 'fetchItems',
      toggleDisabled: 'toggleDisabled',
    }),
    ...mapActions('article', ['scrapeArticles', 'tagArticles']),
    ...mapMutations('fileUpload', ['updateShouldRefresh']),
    ...mapActions('field', { fetchFields: 'fetchItems' }),
    ...mapActions('fieldValue', { fetchFieldValues: 'fetchItems' }),
    ...mapActions('sidebar', ['showWarning']),
    ignoreText(item) {
      if (item.autoDisabled) {
        return 'Ignored*';
      }
      if (item.disabled) {
        return 'Ignored';
      }
      return 'Ignore';
    },
    searchArticles() {
      this.currentPage = 1;
      this.$refs['articles-table'].refresh();
      this.refreshData();
    },
    isTagged(item) {
      if (this.freshTagged.includes(item.id)) {
        return true;
      }
      if (this.freshUntagged.includes(item.id)) {
        return false;
      }
      return this.hasTag(item);
    },
    hasTag(item) {
      return !!item.fieldValues.find((e) => e.field === this.fieldValueDetails.field
      && e.value === this.fieldValueDetails.value);
    },
    toggleArticleTag(item) {
      if (this.isTagged(item)) {
        this.freshTagged = this.freshTagged.filter((e) => e !== item.id);
        if (!this.freshUntagged.includes(item.id) && this.hasTag(item)) {
          this.freshUntagged.push(item.id);
        }
      } else {
        this.freshUntagged = this.freshUntagged.filter((e) => e !== item.id);
        if (!this.freshTagged.includes(item.id) && !this.hasTag(item)) {
          this.freshTagged.push(item.id);
        }
      }
    },
    toggleAll() {
      if (this.allVisibleSelected) {
        Object.values(this.articles).forEach((item) => {
          this.freshTagged = this.freshTagged.filter((e) => e !== item.id);
          if (!this.freshUntagged.includes(item.id) && this.hasTag(item)) {
            this.freshUntagged.push(item.id);
          }
        });
      } else {
        Object.values(this.articles).forEach((item) => {
          this.freshUntagged = this.freshUntagged.filter((e) => e !== item.id);
          if (!this.freshTagged.includes(item.id) && !this.hasTag(item)) {
            this.freshTagged.push(item.id);
          }
        });
      }
    },
    async tagArticlesProxy() {
      const updated = await this.tagArticles({
        tagged: this.freshTagged,
        untagged: this.freshUntagged,
        field: this.fieldValueDetails.field,
        value: this.fieldValueDetails.value,
      });
      if (updated) {
        this.refreshData();
        this.freshTagged = [];
        this.freshUntagged = [];
        this.showWarning({
          title: 'Update successful',
          text: 'Tags have been updated.',
          variant: 'info',
        });
      }
    },
    openArticle(item, index, event) {
      if (this.tagging) {
        this.toggleArticleTag(item);
      } else if (event.ctrlKey) {
        const newPage = this.$router.resolve({ name: 'articles-single', params: { articleId: item.id } });
        window.open(newPage.href, '_blank');
      } else {
        this.$router.push({ name: 'articles-single', params: { articleId: item.id } });
      }
    },
    importClick() {
      if (this.isWebScraper) {
        this.scrapeArticles({ webScraperId: this.dataSourceDetails.id });
      } else {
        this.importArticles({
          dataSourceId: this.dataSourceDetails.id,
          type: this.dataSourceDetails.type,
        });
      }
    },
    async refreshData() {
      const params = {
        data_source: this.dataSourceDetails.id,
        page: this.pagination.page,
      };
      if (this.showDisabled === 'enabled') {
        params.disabled = false;
      } else if (this.showDisabled === 'disabled') {
        params.disabled = true;
      } else {
        params.disabled = undefined;
      }
      if (this.sortBy === 'articleCreated') {
        params.order_by = `${this.sortDesc ? '-' : ''}article_created`;
      } else if (this.sortBy === 'articleModified') {
        params.order_by = `${this.sortDesc ? '-' : ''}article_modified`;
      }
      if (this.searchKeyword) {
        params.search = this.searchKeyword;
        params.search_fields = this.selectedSearchFields;
      }
      const articles = await this.fetchArticles({ params });
      this.hasAutoDisabled = articles.some((a) => a.auto_disabled);
      this.lastUpdated = new Date();
    },
    async handleToggleDisabled(articleId) {
      await this.toggleDisabled(articleId);
      this.refreshData();
    },
  },
};
</script>
<style scoped>
::v-deep .search-btn{
  color: grey !important;
  background-color: #eee !important;
  font-weight: 400 !important;
}
::v-deep .action-col{
  width: 95px;
}
::v-deep .time-col{
  word-break: normal;
}
::v-deep .select-col{
  width: 60px;
}
</style>
