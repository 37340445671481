import { render, staticRenderFns } from "./DocumentationModal.vue?vue&type=template&id=017b2680&scoped=true"
var script = {}
import style0 from "./DocumentationModal.vue?vue&type=style&index=0&id=017b2680&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "017b2680",
  null
  
)

export default component.exports