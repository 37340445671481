<template>
  <div>
    <b-card
      title="User Management"
      class="r-75"
      body-class="p-3"
    >
      <b-row class="mb-2">
        <b-col>
          <b-form-input
            v-model="filterKeyword"
            size="sm"
            placeholder="Search by username"
          />
        </b-col>
      </b-row>
      <table-data
        :items="filteredUsers"
        :fields="userFields"
      >
        <template #cell(actions)="row">
          <b-button
            class="mr-2"
            @click="showUserModal(row.item)"
          >
            Configure
          </b-button>
          <b-button
            variant="danger"
            @click="showDeactivateModal(row.item)"
          >
            Deactivate
          </b-button>
        </template>
      </table-data>
      <b-button
        variant="primary"
        @click="showUserModal({})"
      >
        Add user
      </b-button>
      <b-modal
        id="user-modal"
        :title="`${currentItem.id ? 'Edit' : 'Add'} user`"
        centered
        ok-title="Save user"
        :ok-disabled="$v.currentItem.$invalid"
        @ok="handleOk"
      >
        <b-form-group
          label="Username"
        >
          <b-form-input
            v-if="currentItem.id !== undefined"
            v-model="currentItem.username"
            disabled
          />
          <b-form-input
            v-else
            v-model="currentItem.username"
            :state="!$v.currentItem.username.$invalid"
          />
          <b-form-invalid-feedback
            v-if="!$v.currentItem.username.required"
          >
            This field is required
          </b-form-invalid-feedback>
          <b-form-invalid-feedback
            v-else-if="$v.currentItem.username.$invalid"
          >
            Username must be unique
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          label="First Name"
        >
          <b-form-input
            v-model="currentItem.firstName"
          />
        </b-form-group>
        <b-form-group
          label="Last Name"
        >
          <b-form-input
            v-model="currentItem.lastName"
          />
        </b-form-group>
        <b-form-group
          label="Email"
        >
          <b-form-input
            v-model="currentItem.email"
            :state="!$v.currentItem.email.$invalid"
          />
          <b-form-invalid-feedback
            v-if="!$v.currentItem.email.required"
          >
            This field is required
          </b-form-invalid-feedback>
          <b-form-invalid-feedback
            v-if="!$v.currentItem.email.email"
          >
            Please enter a valid email address
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          v-if="!currentItem.id"
          label="Password"
          invalid-feedback="This field is required"
        >
          <b-form-input
            v-model="currentItem.password"
            :state="!$v.currentItem.password.$invalid"
          />
        </b-form-group>
        <b-form-group
          label="Access level"
        >
          <b-form-radio-group
            v-model="currentItem.groups"
            buttons
            class="w-100"
            :options="groupOptions"
          />
        </b-form-group>
      </b-modal>
      <b-modal
        id="deactivate-user-modal"
        title="Deactivate user"
        ok-title="Deactivate"
        ok-variant="danger"
        @ok="handleDeactivate"
      >
        Deactivate user with username '{{ currentItem.username }}'?
        <br>Deactivated users can only be reactivated by an administrator
      </b-modal>
    </b-card>
    <b-card
      title="Access levels"
      class="r-75 mt-2"
      body-class="p-3"
    >
      <table-data
        :items="groups"
        :fields="groupFields"
      >
        <template #cell(sources)="row">
          <b-badge
            v-for="(source, index) in row.item.sources"
            :key="index"
            class="mr-1"
          >
            {{ source }}
          </b-badge>
        </template>
        <template #cell(actions)="row">
          <b-button
            class="mr-2"
            @click="showGroupModal(row.item)"
          >
            Configure
          </b-button>
        </template>
      </table-data>
    </b-card>
    <b-modal
      id="group-modal"
      title="Manage access level"
      centered
      ok-title="Save group"
      @ok="handleGroupOk"
    >
      <b-form-group
        label="Name"
      >
        <b-form-input
          v-model="currentItem.name"
          disabled
        />
      </b-form-group>
      <b-form-group
        label="AD group Ids"
        description="These AD claim groups will be assigned this access level"
      >
        <string-list
          class="string-list"
          fixed-input
          placeholder="Add group Id..."
          :strings="currentItem.sources"
          @change="updateSources"
        />
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
import update from 'immutability-helper';
import TableData from 'supwiz/components/TableData.vue';
import StringList from 'supwiz/components/StringList.vue';
import endpoints from '@/js/endpoints';
import { mapActions, mapGetters, mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import { validationMixin } from 'vuelidate';
import { required, email, requiredIf } from 'vuelidate/lib/validators';

export default {
  name: 'UserManagement',
  components: {
    StringList,
    TableData,
  },
  mixins: [validationMixin],
  data() {
    return {
      filterKeyword: '',
      currentItem: {},
      userFields: [
        { key: 'username', label: 'Username' },
        { key: 'firstName', label: 'First Name' },
        { key: 'lastName', label: 'Last Name' },
        { key: 'email', label: 'Email' },
        { key: 'actions', label: '' },
      ],
      groupFields: [
        { key: 'name', label: 'Name' },
        { key: 'sources', label: 'Sources' },
        { key: 'actions', label: '' },
      ],
    };
  },
  computed: {
    ...mapGetters('user', { users: 'items', groupOptions: 'groupOptions' }),
    ...mapState('user', ['groups']),
    filteredUsers() {
      return Object.values(this.users)
        .filter((e) => e.username.toLowerCase().includes(this.filterKeyword.toLowerCase()));
    },
  },
  async created() {
    await this.fetchUsers();
    await this.fetchGroups();
  },
  methods: {
    ...mapActions('sidebar', ['showWarning']),
    ...mapActions('user', {
      fetchUsers: 'fetchItems', addUser: 'addItem', updateUser: 'patchItem', deleteUser: 'deleteItem', fetchGroups: 'fetchGroups',
    }),
    updateSources(sources) {
      Object.assign(this.currentItem, update(this.currentItem, { sources }));
    },
    showUserModal(item) {
      this.currentItem = cloneDeep(item);
      if (item.id) {
        const match = this.groupOptions.find((x) => x.text === item.group);
        if (match != null) {
          this.currentItem.groups = match.value;
        } else {
          this.currentItem.groups = null;
        }
      }
      this.$bvModal.show('user-modal');
    },
    showDeactivateModal(item) {
      this.currentItem = cloneDeep(item);
      this.$bvModal.show('deactivate-user-modal');
    },
    async handleOk() {
      if (this.currentItem.groups == null) {
        this.currentItem.groups = [];
      } else if (!Array.isArray(this.currentItem.groups)) {
        this.currentItem.groups = [this.currentItem.groups];
      }
      if (!this.currentItem.id) {
        await this.addUser({ newItem: this.currentItem });
      } else {
        await this.updateUser(this.currentItem);
      }
      await this.fetchUsers();
    },
    async handleDeactivate() {
      await this.deleteUser({ item: this.currentItem });
    },
    showGroupModal(item) {
      this.currentItem = cloneDeep(item);
      this.$bvModal.show('group-modal');
    },
    async handleGroupOk() {
      try {
        await axios.post(
          `${endpoints.group}${this.currentItem.id}/update_sources/`,
          {
            sources: this.currentItem.sources,
          },
          { headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` } },
        );
      } catch (error) {
        this.showWarning({
          title: 'Failed to send feedback',
          text: error.message,
        });
        throw error;
      }
    },
  },
  validations() {
    return {
      currentItem: {
        username: {
          required,
          isUnique(value) {
            return this.currentItem.id || !Object.values(this.users)
              .map((u) => u.username.toLowerCase()).includes(value.toLowerCase());
          },
        },
        email: { required, email },
        password: { required: requiredIf((currentItem) => !currentItem.id) },
      },
    };
  },
};
</script>
