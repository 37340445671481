<template>
  <base-activity
    ref="baseActivity"
    :scraper-id="scraperId"
    :command-id="commandId"
    :indent="indent"
    :allow-expand="!['end for', 'end if'].includes(command.subType)"
    bs-variant="danger"
    v-on="$listeners"
  >
    <template #icon>
      <font-awesome-icon icon="directions" />
    </template>
    <template #header>
      {{ headerContent }}
    </template>
    <b-form-group
      v-if="['if', 'else if'].includes(command.subType)"
      label="Statement:"
    >
      <b-form-input
        :value="command.statement"
        type="text"
        @input="value => $root.$emit('update-scraper', { commandId, prop: 'statement', value })"
      />
    </b-form-group>
    <b-row v-if="command.subType === 'for'">
      <b-col cols="6">
        <b-form-group
          label="Item variable:"
        >
          <b-form-input
            :value="command.item"
            type="text"
            @input="value => $root.$emit('update-scraper', { commandId, prop: 'item', value })"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Iterable variable:"
        >
          <b-form-input
            :value="command.iterable"
            type="text"
            @input="value => $root.$emit('update-scraper', { commandId, prop: 'iterable', value })"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <h6>
      This action supports botscript.
      <b-link v-b-modal.botscript-documentation-modal>
        Click here to see botscript documentation
      </b-link>
    </h6>
  </base-activity>
</template>

<script>
import { FIND_ELEMENT_TYPES } from '@/js/selenium_constants';

import BaseActivity from '@/components/WebScraper/SeleniumCommands/BaseCommand.vue';

export default {
  name: 'ControlFlowCommand',
  components: { BaseActivity },
  props: {
    scraperId: {
      type: Number,
      required: true,
    },
    commandId: {
      type: String,
      required: true,
    },
    command: {
      type: Object,
      required: true,
    },
    indent: {
      type: Number,
      required: true,
    },
  },
  computed: {
    findElementTypes() {
      return Object.values(FIND_ELEMENT_TYPES);
    },
    isMulti: {
      get() {
        return this.command.multi || false;
      },
      set(value) {
        this.$root.$emit('update-scraper', { commandId: this.commandId, prop: 'multi', value });
      },
    },
    headerContent() {
      if (this.command.subType === 'for') {
        return `for ${this.command.item} in ${this.command.iterable}:`;
      }
      if (this.command.subType === 'if') {
        return `if ${this.command.statement}:`;
      }
      if (this.command.subType === 'else if') {
        return `else if ${this.command.statement}:`;
      }
      return this.command.subType;
    },
  },
};
</script>
