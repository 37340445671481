<template>
  <div>
    <b-card
      title="Serving"
      class="mb-3 r-75"
      body-class="p-3"
    >
      <b-row>
        <b-col>
          <display-key-value
            class="my-2"
            key-prop="Search page"
            :clickable="true"
            :value-prop="searchURL"
            :min-key-width="keyWidth"
            @click="goToSearchPage"
            @copy="copySearchPageLink"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <display-key-value
            class="my-2"
            key-prop="Endpoint"
            :clickable="true"
            :value-prop="endpoint"
            :min-key-width="keyWidth"
            @copy="copyEndpoint"
          />
        </b-col>
        <b-col cols="auto">
          <b-button
            v-b-modal.api-documentation-modal
            class="my-2"
            block
          >
            API Documentation
          </b-button>
          <APIDocumentationModal :endpoint="endpoint" />
        </b-col>
      </b-row>
    </b-card>

    <b-card
      title="Test & Stage"
      class="my-3 r-75"
      body-class="p-3"
    >
      <b-row class="my-3">
        <b-col>
          <b-input-group class="w-100">
            <b-input-group-prepend :style="`width:${keyWidth}px;`">
              <b-input-group-text class="bg-primary text-white w-100 justify-content-center key-prop">
                Test version
              </b-input-group-text>
            </b-input-group-prepend>
            <b-input-group-text
              class="r-0 bg-white justify-content-center"
              :class="deployedTestRankerInstance ? 'cursor-pointer' : ''"
              style="min-width: 320px;"
              @click="goToTestDeployed"
            >
              {{ testVersionDisplay }}
            </b-input-group-text>
            <b-input-group-append>
              <b-dropdown
                ref="stageableDropdown"
                right
                boundary="viewport"
                text="Change"
                no-flip
                menu-class="version-dropdown p-1 bg-white"
                no-caret
                toggle-class="w-100"
              >
                <b-table
                  hover
                  class="mb-0 cursor-pointer"
                  show-empty
                  sticky-header="300px"
                  :fields="versionFields"
                  :items="stageableVersions"
                  @row-clicked="deployTestModel"
                >
                  <template #cell(status)="row">
                    <div>
                      <font-awesome-icon
                        v-if="row.item.active"
                        v-b-tooltip.hover.noninteractive.viewport="'Staged Version'"
                        icon="fa-rocket"
                      />
                      <font-awesome-icon
                        v-if="row.item.activeForTesting"
                        v-b-tooltip.hover.noninteractive.viewport="'Test Version'"
                        icon="fa-vial"
                      />
                    </div>
                  </template>
                </b-table>
              </b-dropdown>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="my-3">
        <b-col>
          <b-input-group class="w-100">
            <b-input-group-prepend :style="`width:${keyWidth}px;`">
              <b-input-group-text class="bg-primary text-white w-100 justify-content-center key-prop">
                Test version status
              </b-input-group-text>
            </b-input-group-prepend>
            <b-input-group-text
              class="r-0 bg-white justify-content-center"
              :style="`min-width:${keyWidth}px;`"
            >
              <span :class="getTextClass(deployTestStatus)">
                <b-spinner v-if="deployTestStatus === 'Deploying'" small />  {{ deployTestStatus }}
              </span>
            </b-input-group-text>
            <b-input-group-append>
              <b-button
                v-b-modal.test-version
                :disabled="deployTestStatus !== 'Ready'"
                class="px-3"
                variant="primary"
              >
                Test
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>

      <b-row class="my-3">
        <b-col>
          <b-button
            block
            :disabled="disableTestToBeStaged"
            @click="copyTestToProd"
          >
            <font-awesome-icon icon="angles-down" />
            Stage test version
            <font-awesome-icon icon="angles-down" />
          </b-button>
        </b-col>
      </b-row>
      <b-row class="my-3">
        <b-col>
          <b-input-group class="w-100">
            <b-input-group-prepend :style="`width:${keyWidth}px;`">
              <b-input-group-text class="bg-primary text-white w-100 justify-content-center key-prop">
                Staged version
              </b-input-group-text>
            </b-input-group-prepend>
            <b-input-group-text
              class="r-0 bg-white justify-content-center"
              :class="deployedRankerInstance ? 'cursor-pointer' : ''"
              style="min-width: 320px;"
              @click="goToDeployed"
            >
              {{ stageVersionDisplay }}
            </b-input-group-text>
            <b-input-group-append>
              <b-button
                v-b-modal.unstage-model
                block
                :disabled="deployedRankerInstance == null"
              >
                Unstage
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <b-input-group class=" w-100">
            <b-input-group-prepend :style="`width:${keyWidth}px;`">
              <b-input-group-text class="bg-primary text-white w-100 justify-content-center key-prop">
                Staged version status
              </b-input-group-text>
            </b-input-group-prepend>
            <b-input-group-text class="r-0 r-25-right bg-white justify-content-center" :style="`min-width:${keyWidth}px;`">
              <span :class="getTextClass(deployStatus)">
                <b-spinner v-if="deployStatus === 'Deploying'" small />  {{ deployStatus }}
              </span>
            </b-input-group-text>
          </b-input-group>
        </b-col>
      </b-row>
    </b-card>
    <b-modal id="test-version" size="xl" title="Test search version" hide-footer>
      <b-form-group label="Text" class="mb-2">
        <b-form-textarea
          v-model="text"
          rows="2"
          max-rows="6"
          @keydown.enter.exact.prevent
          @keyup.enter.exact="testEndpoint(save = false)"
        />
      </b-form-group>
      <b-form-group
        v-if="!isTrainable && showContext"
        label="Context"
        description="A context is only relevant to some types of uploaded models."
      >
        <b-form-textarea
          v-model="context"
          rows="2"
          max-rows="6"
          @keydown.enter.exact.prevent
          @keyup.enter.exact="testEndpoint(save = false)"
        />
      </b-form-group>
      <meta-data-form />
      <b-row
        v-if="genAiEnabled"
        no-gutters
        align-v="center"
        class="mb-2"
      >
        <b-col cols="auto">
          <b-form-checkbox v-model="useGpt" class="mr-0 align-items-center" switch>
            Apply GPT reply using top
          </b-form-checkbox>
        </b-col>
        <b-col style="font-size: 14.4px;">
          <b-form-input v-model="gptReplyNumber" type="number" class="d-inline w-auto mr-1 ml-2" min="1" max="10" inline />
          predicted articles
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-button
            variant="primary"
            :disabled="!text"
            @click="testEndpoint(save = false)"
          >
            Send
          </b-button>
          <b-button
            class="ml-2"
            variant="primary"
            :disabled="!text"
            @click="testEndpoint(save = true)"
          >
            Send and save
          </b-button>
        </b-col>
        <b-col cols="auto">
          <b-button v-b-modal.articles-range>
            Articles in range {{ pagination.count ? `(${pagination.count})` : '' }}
          </b-button>
        </b-col>
      </b-row>

      <div v-if="response !== null" class="mt-3">
        <b-list-group v-if="!testResults.length" class="mb-2" style="max-height: 400px; overflow-y:auto;">
          <b-list-group-item v-if="gptReply !== undefined">
            <h5>GPT reply</h5>
            <b-row>
              <b-col>
                <strong>Reply used context: </strong>{{ gptUsedContext }}
                <br>
                <strong>Reply used whitelist: </strong>{{ gptUsedWhitelist }}
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col>
                <p class="mb-0">
                  {{ gptReplyShow }}
                </p>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item class="text-center r-25">
            No articles could be confidently suggested from the data.
          </b-list-group-item>
        </b-list-group>
        <template v-else>
          <b-list-group class="mb-2">
            <b-list-group-item v-if="gptReply !== undefined">
              <h5>GPT reply</h5>
              <b-row>
                <b-col>
                  <strong>Reply used context: </strong>{{ gptUsedContext }}
                  <br>
                  <strong>Reply used whitelist: </strong>{{ gptUsedWhitelist }}
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col>
                  <p class="mb-0">
                    {{ gptReplyShow }}
                  </p>
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
          <article-output
            :data="testResults"
            :show-details="true"
          />
        </template>
      </div>
    </b-modal>
    <b-modal
      id="unstage-model"
      title="Unstage"
      ok-title="Unstage"
      ok-variant="danger"
      @ok="unstageModel"
    >
      Are you sure you want to unstage this search engine?
    </b-modal>
    <b-modal
      id="articles-range"
      size="xl"
      :title="`Articles in range (${pagination.count})`"
      ok-only
      @hide="clearSearch"
    >
      <b-row class="mb-2">
        <b-col>
          <b-input-group>
            <b-input-group-prepend>
              <b-dropdown
                :disabled="isFetchingStageableVersions"
                :text="`Search (${getSelectedSearchFieldsText})`"
                toggle-class="search-btn"
              >
                <b-dropdown-form>
                  <b-form-checkbox-group
                    v-model="selectedSearchFields"
                    :options="searchOptions"
                    stacked
                  />
                </b-dropdown-form>
              </b-dropdown>
            </b-input-group-prepend>
            <b-form-input v-model="searchKeyword" type="search" @keyup.enter="searchArticles" />
            <b-input-group-append>
              <b-button variant="primary" @click="searchArticles">
                Search
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="mt-3 mb-2">
        <b-col>
          <b-pagination
            v-model="currentPage"
            size="sm"
            class="my-auto"
            :total-rows="pagination.count"
            :per-page="pagination.perPage"
            aria-controls="articles-table"
          />
        </b-col>
      </b-row>
      <b-table
        id="articles-table"
        ref="articles-table"
        :fields="articleFields"
        :current-page="currentPage"
        :per-page="pagination.perPage"
        class="mb-0"
        :items="articleItemsProvider"
        show-empty
        empty-text="There are currently no articles."
        hover
      />
    </b-modal>
  </div>
</template>

<script>
import {
  mapGetters, mapActions, mapState, mapMutations,
} from 'vuex';
import DisplayKeyValue from 'supwiz/components/DisplayKeyValue.vue';
import { percentageFormatter, textFormatter } from 'supwiz/util/formatters';
import endpoints from '@/js/endpoints';
import APIDocumentationModal from '@/components/Ranker/APIDocumentationModal.vue';
import ArticleOutput from '@/components/Ranker/ArticleOutput.vue';
import MetaDataForm from '@/components/Ranker/MetaDataForm.vue';
import { objToCamel } from '@/js/utils';

function dateFormatter(date) {
  if (date) {
    return new Date(date).toLocaleString();
  }
  return date;
}

export default {
  name: 'Testing',
  components: {
    ArticleOutput,
    DisplayKeyValue,
    APIDocumentationModal,
    MetaDataForm,
  },
  data() {
    return {
      text: '',
      context: '',
      showContext: false,
      response: null,
      gptReplyNumber: 1,
      useGpt: false,
      articleFields: [
        {
          key: 'title', formatter: (value) => textFormatter(value, 100),
        },
        { key: 'currentUrl' },
      ],
      searchKeyword: '',
      selectedSearchFields: ['title', 'text', 'current_url'],
      searchOptions: [
        { text: 'Title', value: 'title' },
        { text: 'Text', value: 'text' },
        { text: 'Current url', value: 'current_url' },
      ],
      versionFields: [
        { key: 'id', label: 'ID', tdClass: 'table-nobreak' },
        { key: 'createdTime', label: 'Created', formatter: dateFormatter },
        { key: 'accuracy', formatter: percentageFormatter },
        { key: 'status', label: '' },
      ],
      intervalId: null,
      stageableVersions: [],
    };
  },
  computed: {
    ...mapGetters('pipelineSource', ['articleTrainingItems']),
    ...mapGetters('auth', ['headerAuthorization', 'genAiEnabled']),
    ...mapGetters('ranker', { rankerDetails: 'details' }),
    ...mapGetters('ranker', ['isTrainable', 'metaData', 'deployedRankerInstance', 'deployedTestRankerInstance']),
    ...mapGetters('pipeline', ['isArticlePrediction']),
    ...mapState('article', ['pagination']),
    ...mapState('ranker', ['isFetchingStageableVersions']),
    testVersionDisplay() {
      if (this.deployedTestRankerInstance) {
        return `ID: ${this.deployedTestRankerInstance?.id}, Created: ${dateFormatter(this.deployedTestRankerInstance.createdTime)}`;
      }
      return 'No test version selected';
    },
    stageVersionDisplay() {
      if (this.deployedRankerInstance) {
        return `ID: ${this.deployedRankerInstance?.id}, Created: ${dateFormatter(this.deployedRankerInstance.createdTime)}`;
      }
      return 'No active staged version ';
    },
    deployStatus() {
      if (!this.deployedRankerInstance) {
        return '-';
      }
      if (this.rankerDetails.deploySuccess) {
        return 'Ready';
      }
      if (this.rankerDetails.isDeploying) {
        return 'Deploying';
      }
      return 'Failed';
    },
    deployTestStatus() {
      if (!this.deployedTestRankerInstance) {
        return '-';
      }
      if (this.rankerDetails.deploySuccessTest) {
        return 'Ready';
      }
      if (this.rankerDetails.isDeployingTest) {
        return 'Deploying';
      }
      return 'Failed';
    },
    disableTestToBeStaged() {
      if (this.deployedTestRankerInstance == null) {
        return true;
      }
      if (!this.rankerDetails.deploySuccessTest) {
        return true;
      }
      return this.deployedTestRankerInstance.id === this.deployedRankerInstance?.id;
    },
    endpoint() {
      return `https://${window.location.hostname}${endpoints.rank}${this.rankerDetails.id}/`;
    },
    searchURL() {
      return `https://${window.location.hostname}/search/${this.rankerDetails.id}/`;
    },
    keyWidth() {
      return 180;
    },
    deviceType() {
      const ua = navigator.userAgent;
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return 'tablet';
      }
      if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
        return 'mobile';
      }
      return 'desktop';
    },
    testResults() {
      return this.response?.data?.result || [];
    },
    gptReply() {
      return this.response.data?.gpt_reply;
    },
    gptUsedContext() {
      return !!this.response.data?.gpt_reply_used_context;
    },
    gptUsedWhitelist() {
      return !!this.response.data?.gpt_reply_used_whitelist;
    },
    gptReplyShow() {
      if (this.gptUsedContext || this.gptUsedWhitelist) {
        return this.gptReply;
      }
      return 'A reply could not be confidently generated from the data';
    },
    getSelectedSearchFieldsText() {
      return this.selectedSearchFields.map((e) => this.searchOptions
        .find((o) => o.value === e).text).join(', ');
    },
    currentPage: {
      get() {
        return this.pagination.page;
      },
      set(val) {
        this.updatePagination({ page: val });
      },
    },
    articleParams() {
      const params = {
        page: this.pagination.page,
        disabled: false,
        order_by: '-article_created',
      };
      if (!this.classicSearchEnabled) {
        params.data_source = this.articleTrainingItems.map((e) => e.dataSource);
      }
      return params;
    },
    classicSearchEnabled() {
      return this.deployedRankerInstance?.config?.use_classic_search
      && this.deployedRankerInstance?.classicSearchWeight > 0;
    },
  },
  mounted() {
    this.fetchArticles({ params: this.articleParams });
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  async created() {
    this.refreshRanker();
    this.initInterval();
    this.stageableVersions = await this.getStageableVersions(this.rankerDetails.id);
  },
  methods: {
    ...mapActions('ranker', ['testRanker', 'getStageableVersions']),
    ...mapMutations('article', ['updatePagination']),
    ...mapActions('article', {
      fetchArticles: 'fetchItems',
    }),
    ...mapActions('ranker', { fetchRanker: 'fetchItemDetails' }),
    ...mapActions('rankerInstance', { patchRankerInstance: 'patchItem', fetchRankerInstances: 'fetchItems' }),
    getTextClass(value) {
      if (value === 'Ready') {
        return 'text-success';
      } if (value === 'Failed') {
        return 'text-warning';
      }
      return '';
    },
    initInterval() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      this.intervalId = setInterval(this.refreshRanker, 5000);
    },
    async refreshRanker() {
      const rankerId = this.rankerDetails.id;
      if (rankerId != null) {
        await this.fetchRanker({
          id: rankerId,
          refreshing: true,
        });
      }
    },
    copyEndpoint() {
      navigator.clipboard.writeText(this.endpoint);
    },
    copySearchPageLink() {
      navigator.clipboard.writeText(this.searchURL);
    },
    goToDeployed() {
      if (this.deployedRankerInstance) {
        this.$router.push({
          name: 'ranker-versions-single',
          params: { rankerInstanceId: this.deployedRankerInstance.id },
        });
      }
    },
    goToTestDeployed() {
      if (this.deployedTestRankerInstance) {
        this.$router.push({
          name: 'ranker-versions-single',
          params: { rankerInstanceId: this.deployedTestRankerInstance.id },
        });
      }
    },
    goToSearchPage() {
      this.$router.push({ name: 'search', params: { rankerId: this.rankerDetails.id } });
    },
    async testEndpoint(save) {
      this.response = await this.testRanker({
        rankerId: this.rankerDetails.id,
        text: this.text,
        context: this.context,
        metaData: this.metaData,
        device: this.deviceType,
        url: window.location.href,
        save,
        includeGptReply: this.useGpt,
        topk: this.gptReplyNumber,
        useUnstagedRules: false,
        testModel: true,
      });
    },
    clearSearch() {
      this.searchKeyword = '';
      this.currentPage = 1;
      this.$refs['articles-table'].refresh();
    },
    searchArticles() {
      this.currentPage = 1;
      this.$refs['articles-table'].refresh();
    },
    async articleItemsProvider() {
      const params = JSON.parse(JSON.stringify(this.articleParams));
      if (this.searchKeyword) {
        params.search = this.searchKeyword;
        params.search_fields = this.selectedSearchFields;
      }
      const articles = await this.fetchArticles({ params });
      return Object.values(articles.map((c) => objToCamel(c)));
    },
    deployTestModel(stageableVersion) {
      this.deploy(stageableVersion.id, true, false);
      this.$refs.stageableDropdown.hide();
    },
    unstageModel() {
      this.deploy(this.deployedRankerInstance.id, false, true);
    },
    copyTestToProd() {
      this.deploy(this.deployedTestRankerInstance.id, true, true);
    },
    async deploy(instanceId, activate, inProd) {
      if (instanceId == null) {
        return;
      }
      const data = {
        id: instanceId,
      };
      if (inProd) {
        data.active = activate;
      } else {
        data.activeForTesting = activate;
      }
      await this.patchRankerInstance(data);
      await this.fetchRanker({
        id: this.rankerDetails.id,
        refreshing: true,
      });
      this.stageableVersions = await this.getStageableVersions(this.rankerDetails.id);
    },
  },
};
</script>
<style scoped>
::v-deep .version-dropdown{
  width: 500px;
  overflow:hidden;
}
</style>
