<template>
  <div>
    <statistics-filters
      :metrics="metricOptions"
      :extra-filters="extraFilters"
      :base-filters="baseFilters"
      calendar-tooltip="Select date range for computing statistics"
      @newFilters="(val) => needsRefresh = val"
      @fetchData="fetchStatistics"
      @settings="$bvModal.show('statistics-settings-modal')"
    />
    <b-overlay
      variant="white stat-overlay"
      :show="needsRefresh"
      :opacity="'1.0'"
      :no-center="needsRefresh"
      style="min-height:50px"
    >
      <template
        v-if="needsRefresh"
        #overlay
      >
        <h4
          style="padding-top:20px"
          class="my-auto text-center pb-3"
        >
          Click "Show" to refresh statistics.
        </h4>
      </template>
      <b-card v-if="!dataReady" class="r-75 shadow text-center">
        <b-spinner v-if="computingStats" />
        <h4 v-else class="mb-0">
          There is no data to show.
        </h4>
      </b-card>
      <div v-else>
        <b-row no-gutters>
          <b-col
            class="mb-3 pr-xl-2"
            cols="12"
            xl="4"
          >
            <NewKPICard
              title="Self-service rate"
              :is-loading="isLoadingKPI('not_useful_count') || isLoadingKPI('count')"
              format-type="percent"
              :value="selfServiceRate"
            />
          </b-col>
          <b-col
            class="mb-3 px-xl-2"
            cols="12"
            xl="4"
          >
            <NewKPICard
              title="Estimated cost savings"
              format-type="currency"
              :value="costSaving"
              :is-loading="isLoadingKPI('estimated_cost_savings')"
              :currency="currency"
              truncate
            />
          </b-col>
          <b-col
            class="mb-3 pl-xl-2"
            cols="12"
            xl="4"
          >
            <NewKPICard
              title="Query Volume"
              :is-loading="isLoadingKPI('count')"
              format-type="number"
              :value="queryVolume"
              truncate
            />
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col>
            <b-card
              class="r-75 shadow"
              body-class="p-3"
            >
              <b-list-group>
                <component
                  :is="getDataDisplayComponent(metric)"
                  v-for="(metric, index) in chosenMetrics"
                  :key="index"
                  :metric="metric"
                />
              </b-list-group>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </b-overlay>
    <b-modal
      id="statistics-settings-modal"
      centered
      title="Statistics Settings"
      ok-title="Save"
      @ok="updateStatisticsSettings()"
    >
      <b-spinner v-if="fetchingStatisticsSettings" />
      <template v-else-if="statisticsSettings">
        <edit-key-value
          class="my-2"
          key-prop="Average article cost"
          description="Value of automatically presented article"
          :value-prop="statisticsSettings.costPerQuery"
          :min-key-width="keyWidth"
          type="number"
          @input="(x) => setStatisticsSetting({ key: 'costPerQuery', value: x })"
        />
        <edit-key-value
          class="my-2"
          key-prop="Currency"
          description="Currency used in statistics"
          :value-prop="statisticsSettings.currency"
          :min-key-width="keyWidth"
          :options="currencyOptions"
          type="select"
          @input="(x) => setStatisticsSetting({ key: 'currency', value: x })"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  mapGetters, mapState, mapActions, mapMutations,
} from 'vuex';
import EditKeyValue from 'supwiz/components/EditKeyValue.vue';
import NewKPICard from 'supwiz/components/NewKPICard.vue';
import StatisticsFilters from 'supwiz/components/statisticsFilters/StatisticsFilters.vue';
import { metricOptions, intervalOptions } from '@/js/constants';
import DataDisplay from '@/components/Ranker/DataDisplay.vue';
import DataDisplayTopics from '@/components/Ranker/DataDisplayTopics.vue';
import DataDisplayTraffic from '@/components/Ranker/DataDisplayTraffic.vue';

export default {
  name: 'Statistics',
  components: {
    StatisticsFilters,
    DataDisplay,
    DataDisplayTopics,
    DataDisplayTraffic,
    EditKeyValue,
    NewKPICard,
  },
  data() {
    return {
      showFilters: true,
      needsRefresh: true,
      metricOptions,
      currencyOptions: [
        { value: 'EUR', text: 'EUR' },
        { value: 'DKK', text: 'DKK' },
        { value: 'USD', text: 'USD' },
      ],
      keyWidth: 170,
    };
  },
  computed: {
    ...mapGetters('auth', ['headerAuthorization']),
    ...mapState('statisticsFiltersStore', ['filters']),
    ...mapState('ranker', {
      rankerDetails: 'details',
      stats: 'stats',
      computingStats: 'computingStats',
      fetchingStatisticsSettings: 'fetchingStatisticsSettings',
      statisticsSettings: 'statisticsSettings',
    }),
    baseFilters() {
      return [
        {
          key: 'metrics', label: 'Metrics', type: 'multiselect', options: this.metricOptions, draggable: true, tooltip: 'Select which metrics should be computed and in what order',
        },
        {
          key: 'interval', label: 'Interval', type: 'select', options: Object.values(intervalOptions), tooltip: 'Select time interval',
        },
      ];
    },
    extraFilters() {
      return [
        {
          key: 'userTypes', label: 'User Type', type: 'multiselect', options: this.userTypeOptions, tooltip: 'Select which user types to include in statistics',
        },
      ];
    },
    userTypeOptions() {
      return [
        { value: 'bot', text: 'AI bot', tooltip: '' },
        { value: 'assist', text: 'AI assist', tooltip: '' },
        { value: 'supsearch_user', text: 'SupSearch User', tooltip: '' },
        { value: 'end_user', text: 'End user', tooltip: '' },
      ];
    },
    dataReady() {
      return !!Object.values(this.stats).length;
    },
    KPIsReady() {
      return this.stats;
    },
    queryVolume() {
      return this.stats.count ? this.stats.count : 0;
    },
    selfServiceRate() {
      const count = this.stats.count;
      const notUseful = this.stats.not_useful_count;
      if (count !== undefined && notUseful !== undefined && count !== 0) {
        return ((count - notUseful) / count * 100);
      }
      return null;
    },
    currency() {
      return this.statisticsSettings?.currency;
    },
    costSaving() {
      return this.stats.estimated_cost_savings;
    },
    chosenMetrics() {
      return this.filters.metrics;
    },
  },
  mounted() {
    this.fetchStatisticsSettings(this.rankerDetails.id);
  },
  methods: {
    ...mapMutations('ranker', ['setStatisticsSetting']),
    ...mapActions('ranker', ['fetchStatisticsSettings', 'updateStatisticsSettings', 'computeRankerStats']),
    ...mapMutations('statisticsFiltersStore', ['setFilters']),
    getDataDisplayComponent(metric) {
      switch (metric) {
        case 'knowledge_gaps':
        case 'top_keywords':
          return 'data-display-topics';
        case 'traffic':
          return 'data-display-traffic';
        default:
          return 'data-display';
      }
    },
    fetchStatistics() {
      this.computeRankerStats(this.rankerDetails);
      this.$root.$emit('resetStatisticsDetails', true);
    },
    isLoadingKPI(key) {
      return this.stats[key] === undefined;
    },
  },
};
</script>
<style scoped>
.filters-btn{
  width:70px;
  font-size: 0.9rem;
  color: #005f89 !important;
}
::v-deep .stat-overlay{
  border-radius: 0.75rem;
}
</style>
