<template>
  <b-row class="justify-content-center pt-5">
    <b-col cols="4">
      <b-card
        align="center"
        title="You are now signed out"
      >
        <router-link :to="{ name: 'login' }">
          Sign in again
        </router-link>
        <template #footer>
          <div>
            <img
              style="max-width: 25%"
              src="@/assets/supwiz_dark_text.svg"
              alt="SupWiz Logo"
            >
          </div>
        </template>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>

export default {
  name: 'LogoutPage',
  mounted() {
    this.$store.dispatch('auth/logout');
  },
};
</script>

<style scoped>
</style>
