<template>
  <div class="border r-25 p-1">
    <draggable
      :list="commandIds"
      role="tablist"
      filter=".card-body"
      :prevent-on-filter="false"
    >
      <component
        :is="commandComponents[commandId]"
        v-for="(commandId, idx) in commandIds"
        :ref="commandId"
        :key="commandId"
        :scraper-id="scraperId"
        :command-id="commandId"
        :command="commands[commandId]"
        :indent="indentionTable[idx]"
        @cloneCommand="cloneCommand"
        @deleteCommand="deleteCommand"
      />
    </draggable>
    <b-btn-group style="display: flex">
      <b-btn
        v-b-tooltip.hover
        title="SetVariable"
        variant="secondary"
        @click="addCommand(SET_VARIABLE)"
      >
        Set Variable
      </b-btn>
      <b-btn
        v-b-tooltip.hover
        title="FindElement(s)"
        variant="primary"
        @click="addCommand(FIND_ELEMENT)"
      >
        Find Element(s)
      </b-btn>
      <b-btn
        v-b-tooltip.hover
        title="GetAttribute"
        variant="info"
        @click="addCommand(GET_ATTRIBUTE)"
      >
        Get Attribute
      </b-btn>
      <b-btn
        v-b-tooltip.hover
        title="Wait"
        variant="dark"
        @click="addCommand(WAIT)"
      >
        Wait
      </b-btn>
      <b-btn
        v-if="allowedDangerCommands.includes(ADD_LINK)"
        v-b-tooltip.hover
        title="AddLink"
        variant="danger"
        @click="addCommand(ADD_LINK)"
      >
        Add Link
      </b-btn>
      <b-dropdown text="Control Flow" variant="danger">
        <b-dropdown-item @click="addCommand(CONTROL_FLOW, 'if')">
          If
        </b-dropdown-item>
        <b-dropdown-item :disabled="!allowElseIf" @click="addCommand(CONTROL_FLOW, 'else if')">
          Else if
        </b-dropdown-item>
        <b-dropdown-item :disabled="!allowEndIf" @click="addCommand(CONTROL_FLOW, 'end if')">
          End if
        </b-dropdown-item>
        <b-dropdown-item @click="addCommand(CONTROL_FLOW, 'for')">
          For
        </b-dropdown-item>
        <b-dropdown-item :disabled="!allowEndFor" @click="addCommand(CONTROL_FLOW, 'end for')">
          End for
        </b-dropdown-item>
      </b-dropdown>
      <b-btn
        v-if="allowedDangerCommands.includes(ADD_ARTICLE)"
        v-b-tooltip.hover
        title="AddArticle"
        variant="success"
        @click="addCommand(ADD_ARTICLE)"
      >
        Add Article
      </b-btn>
    </b-btn-group>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';
import { v4 as uuidv4 } from 'uuid';
import {
  FIND_ELEMENT, GET_ATTRIBUTE, ADD_LINK, ADD_ARTICLE, WAIT, CONTROL_FLOW, SET_VARIABLE,
} from '@/js/selenium_constants';

import AddArticleCommand from '@/components/WebScraper/SeleniumCommands/AddArticleCommand.vue';
import AddLinkCommand from '@/components/WebScraper/SeleniumCommands/AddLinkCommand.vue';
import ControlFlowCommand from '@/components/WebScraper/SeleniumCommands/ControlFlowCommand.vue';
import FindElementCommand from '@/components/WebScraper/SeleniumCommands/FindElementCommand.vue';
import GetAttributeCommand from '@/components/WebScraper/SeleniumCommands/GetAttributeCommand.vue';
import SetVariableCommand from '@/components/WebScraper/SeleniumCommands/SetVariableCommand.vue';
import WaitCommand from '@/components/WebScraper/SeleniumCommands/WaitCommand.vue';

function commandTypeToComponent(commandType) {
  if (commandType === FIND_ELEMENT) {
    return FindElementCommand;
  }
  if (commandType === GET_ATTRIBUTE) {
    return GetAttributeCommand;
  }
  if (commandType === WAIT) {
    return WaitCommand;
  }
  if (commandType === ADD_LINK) {
    return AddLinkCommand;
  }
  if (commandType === ADD_ARTICLE) {
    return AddArticleCommand;
  }
  if (commandType === CONTROL_FLOW) {
    return ControlFlowCommand;
  }
  if (commandType === SET_VARIABLE) {
    return SetVariableCommand;
  }
  throw new Error(`Unknown activity type ${commandType}`);
}

export default {
  name: 'CommandsComponent',
  components: { Draggable },
  props: {
    scraperId: {
      type: Number,
      required: true,
    },
    commandIds: {
      type: Array,
      required: true,
    },
    commands: {
      type: Object,
      required: true,
    },
    allowedDangerCommands: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      FIND_ELEMENT,
      GET_ATTRIBUTE,
      WAIT,
      ADD_LINK,
      ADD_ARTICLE,
      CONTROL_FLOW,
      SET_VARIABLE,
    };
  },
  computed: {
    commandComponents() {
      const components = {};
      for (const [id, command] of Object.entries(this.commands)) {
        components[id] = commandTypeToComponent(command.type);
      }
      return components;
    },
    indentionTable() {
      let indent = 0;
      const indentList = [];
      for (const uid of this.commandIds) {
        const command = this.commands[uid];
        if (command && command.type === CONTROL_FLOW) {
          if (['if', 'for'].includes(command.subType)) {
            indentList.push(indent);
            indent += 1;
          } else if (['end if', 'end for'].includes(command.subType)) {
            indent = Math.max(indent - 1, 0);
            indentList.push(indent);
          } else if (command.subType === 'else if') {
            indentList.push(Math.max(indent - 1, 0));
          } else {
            indentList.push(indent);
          }
        } else {
          indentList.push(indent);
        }
      }
      return indentList;
    },
    controlFlows() {
      return this.commandIds.map((i) => this.commands[i]).filter((x) => x != null && x.type === 'controlFlow');
    },
    allowEndFor() {
      return this.controlFlows.filter((x) => x.subType === 'for').length > this.controlFlows.filter((x) => x.subType === 'end for').length;
    },
    allowEndIf() {
      return this.controlFlows.filter((x) => x.subType === 'if').length > this.controlFlows.filter((x) => x.subType === 'end if').length;
    },
    allowElseIf() {
      return this.controlFlows.filter((x) => x.subType === 'if').length > 0;
    },
  },
  methods: {
    addCommand(commandType, subType = null) {
      const cid = uuidv4();
      const command = {
        type: commandType,
        id: cid,
        target_var: '',
        source_var: '',
        param_type: '',
        param: '',
      };
      if (commandType === FIND_ELEMENT) {
        command.target_var = 'elem';
        command.source_var = 'driver';
        command.param_type = 'id';
        command.param = '""';
        command.multi = false;
        command.nullable = false;
      } else if (commandType === GET_ATTRIBUTE) {
        command.target_var = 'attr';
        command.source_var = 'link';
        command.param = '"innerText"';
        command.nullable = false;
      } else if (commandType === ADD_LINK) {
        command.source_var = 'link';
      } else if (commandType === ADD_ARTICLE) {
        command.source_var = 'text';
        command.title = '';
        command.title_var = '';
        command.external_id = '';
        command.external_id_var = '';
        command.tags = [];
      } else if (commandType === WAIT) {
        command.sleep_duration = 2.5;
      } else if (commandType === CONTROL_FLOW) {
        command.subType = subType;
        command.statement = '';
        command.iterable = '';
        command.item = '';
      }
      this.$root.$emit('add-command', { cid, command });
    },
    cloneCommand(copyId) {
      const index = this.commandIds.indexOf(copyId);
      if (index < 0) {
        return;
      }
      const cid = uuidv4();
      const cloned = { ...this.commands[copyId], id: cid };
      this.$root.$emit('clone-command', { cid, cloned, index });
    },
    deleteCommand(deleteId) {
      const index = this.commandIds.indexOf(deleteId);
      if (index < 0) {
        return;
      }
      this.$root.$emit('delete-command', { index, deleteId });
    },
  },
};
</script>

<style scoped>

</style>
