<template>
  <div>
    <b-card
      title="Task Overview"
      class="r-75"
      body-class="p-3"
    >
      <b-row class="my-3">
        <b-col class="my-auto">
          <b-pagination
            v-model="currentPage"
            size="sm"
            class="my-auto"
            :total-rows="pagination.count"
            :per-page="pagination.perPage"
            aria-controls="tasks-table"
          />
        </b-col>
      </b-row>
      <b-table
        id="tasks-table"
        ref="taskTable"
        :fields="fields"
        :current-page="currentPage"
        :per-page="pagination.perPage"
        :items="itemsProvider"
        show-empty
        empty-text="No tasks found"
      >
        <template #cell(aborted)="row">
          <b-button
            v-if="row.item.status === 'pending'"
            size="sm"
            variant="warning"
            class="py-0 ml-2"
            @click="handleAbortTask(row.item)"
          >
            Abort
          </b-button>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>

import { mapActions, mapState, mapMutations } from 'vuex';
import { objToCamel } from '@/js/utils';
import { taskType2Text } from '@/js/constants';
import { snakeCaseToText } from 'supwiz/util/data';

export default {
  name: 'TaskOverview',
  data() {
    return {
      fields: [
        { key: 'id', label: 'Id' },
        { key: 'finishedTime', label: 'Finished time', formatter: (date) => new Date(date).toLocaleString('en-GB') },
        { key: 'type', label: 'Type', formatter: (type) => this.formatTaskType(type) },
        { key: 'progress', label: 'Progress' },
        { key: 'status', label: 'Status' },
        { key: 'aborted', label: '' },
      ],
    };
  },
  computed: {
    ...mapState('task', { tasks: 'items' }),
    ...mapState('task', ['pagination']),
    filteredTasks() {
      return Object.values(this.tasks);
    },
    currentPage: {
      get() {
        return this.pagination.page;
      },
      set(val) {
        this.updatePagination({ page: val });
      },
    },
  },
  methods: {
    ...mapActions('task', ['fetchTaskList', 'abortTask']),
    ...mapActions('task', { fetchTasks: 'fetchItems' }),
    ...mapMutations('task', ['updatePagination']),
    formatTaskType(type) {
      return taskType2Text[type] || snakeCaseToText(type);
    },
    async handleAbortTask(item) {
      await this.abortTask(item.celeryId);
      this.$refs.taskTable.refresh();
    },
    async itemsProvider() {
      const tasks = await this.fetchTasks({
        params: {
          page: this.pagination.page,
        },
      });
      return Object.values(tasks.map((c) => objToCamel(c)));
    },
  },
};
</script>
