const BACKEND_PATH = '/api';

export default {
  obtainJWT: `${BACKEND_PATH}/auth/obtain-token/`,
  refreshJWT: `${BACKEND_PATH}/auth/refresh-token/`,
  verifyJWT: `${BACKEND_PATH}/auth/verify-token/`,
  ranker: `${BACKEND_PATH}/ranker/`,
  rankerInstance: `${BACKEND_PATH}/ranker-instance/`,
  rank: `${BACKEND_PATH}/rank/`,
  feedback: `${BACKEND_PATH}/feedback/`,
  internalFeedback: `${BACKEND_PATH}/internal-feedback/`,
  statisticsConfig: `${BACKEND_PATH}/statistics-config/`,
  dataSource: `${BACKEND_PATH}/data-source/`,
  dataSourceUpload: `${BACKEND_PATH}/data-source-upload/`,
  dataSourceZendesk: `${BACKEND_PATH}/data-source-zendesk/`,
  dataSourceSupchat: `${BACKEND_PATH}/data-source-supchat/`,
  dataSourceTopdesk: `${BACKEND_PATH}/data-source-topdesk/`,
  dataSourcePuzzel: `${BACKEND_PATH}/data-source-puzzel/`,
  dataSourceTicketAnalyzer: `${BACKEND_PATH}/data-source-ticketanalyzer/`,
  webScraper: `${BACKEND_PATH}/web-scraper/`,
  dataSourceRightAnswers: `${BACKEND_PATH}/data-source-rightanswers/`,
  dataSourceBomgar: `${BACKEND_PATH}/data-source-bomgar/`,
  dataSourceRanker: `${BACKEND_PATH}/data-source-ranker/`,
  dataSourceServiceNow: `${BACKEND_PATH}/data-source-servicenow/`,
  dataSourceOnenote: `${BACKEND_PATH}/data-source-onenote/`,
  dataSourceSharepoint: `${BACKEND_PATH}/data-source-sharepoint/`,
  dataSourceByRanker: `${BACKEND_PATH}/data-source/ranker/`,
  article: `${BACKEND_PATH}/article/`,
  articleLightView: `${BACKEND_PATH}/article-view/`,
  chat: `${BACKEND_PATH}/chat/`,
  user: `${BACKEND_PATH}/user/`,
  group: `${BACKEND_PATH}/group/`,
  task: `${BACKEND_PATH}/task/`,
  ticket: `${BACKEND_PATH}/ticket/`,
  query: `${BACKEND_PATH}/query/`,
  question: `${BACKEND_PATH}/question/`,
  fileUpload: `${BACKEND_PATH}/file-upload/`,
  languageModel: `${BACKEND_PATH}/language-model/`,
  language: `${BACKEND_PATH}/language/`,
  pipeline: `${BACKEND_PATH}/pipeline/`,
  pipelineSource: `${BACKEND_PATH}/pipeline-source/`,
  pipelineBuild: `${BACKEND_PATH}/pipeline-build/`,
  field: `${BACKEND_PATH}/field/`,
  fieldValue: `${BACKEND_PATH}/field-value/`,
  datapoint: `${BACKEND_PATH}/datapoint/`,
  fieldValueOrigin: `${BACKEND_PATH}/field-value-origin/`,
  ssoEnabled: `${BACKEND_PATH}/sso/enabled`,
  globalConfig: `${BACKEND_PATH}/global-config/`,
  customRule: `${BACKEND_PATH}/custom-rule/`,
  stopWords: `${BACKEND_PATH}/customer-stop-word/`,
  widgetConfig: `${BACKEND_PATH}/widget-config/`,
  searchFilterField: `${BACKEND_PATH}/search-filter-field/`,
  searchFilterValue: `${BACKEND_PATH}/search-filter-value/`,
  metaDataField: `${BACKEND_PATH}/meta-data-field/`,
  swaggerFile: `${BACKEND_PATH}/docs/swagger.json`,
  emailEnabled: `${BACKEND_PATH}/email-enabled/`,
};
