<template>
  <div>
    <b-card
      title="Tickets"
      class="r-75"
      body-class="p-3"
    >
      <TaskStatus :task-types="taskTypes" />
      <b-row class="my-2">
        <b-col class="my-auto">
          <b-pagination
            v-model="currentPage"
            size="sm"
            class="my-auto"
            :total-rows="pagination.count"
            :per-page="pagination.perPage"
            aria-controls="tickets-table"
          />
        </b-col>
        <b-col cols="auto" class="my-auto">
          <b-button
            v-if="dataSourceDetails.type !== dataSourceTypes.UPLOAD.value"
            v-b-modal.import-modal
            variant="primary"
          >
            Import tickets
          </b-button>
        </b-col>
      </b-row>
      <b-table
        id="tickets-table"
        ref="tickets-table"
        :fields="fields"
        tbody-tr-class="cursor-pointer"
        :per-page="pagination.perPage"
        :caption="tableCaption"
        :items="ticketsArray"
        class="mb-0"
        show-empty
        empty-text="There are currently no tickets."
        hover
        @row-clicked="openTicket"
      >
        <template #cell(actions)="data">
          <b-button
            size="sm"
            :disabled="isDisabling"
            :variant="data.item.disabled ? 'danger' : 'primary'"
            @click.stop="handleToggleDisabled(data.item.id)"
          >
            {{ data.item.disabled ? 'Ignored' : 'Ignore' }}
          </b-button>
        </template>
      </b-table>
      <tickets-upload-modal />
      <import-modal type="tickets" />
    </b-card>
    <br />
    <file-upload-table v-if="isUploadType" data-type="ticket" />
  </div>
</template>

<script>

import {
  mapState, mapActions, mapMutations, mapGetters,
} from 'vuex';
import { dataSourceTypes } from '@/js/constants';
import { objToCamel } from '@/js/utils';
import TicketsUploadModal from '@/components/DataSource/TicketsUploadModal.vue';
import ImportModal from '@/components/DataSource/ImportModal.vue';
import TaskStatus from '@/components/TaskStatus.vue';
import FileUploadTable from '../../components/DataSource/FileUploadTable.vue';

export default {
  name: 'DataSourceTickets',
  components: {
    ImportModal, TicketsUploadModal, FileUploadTable, TaskStatus,
  },
  data() {
    return {
      dataSourceTypes,
      ticketsFile: null,
      fields: [
        { key: 'id', label: 'Id', tdClass: 'table-nobreak' },
        {
          key: 'externalId',
          label: 'External Id',
          formatter: (value, key, item) => item.displayId || item.externalId,
        },
        { key: 'ticketCreated', label: 'Ticket Created', formatter: (date) => new Date(date).toLocaleString('en-GB') },
        'shortDescription',
        {
          key: 'actions', label: '', tdClass: 'table-nobreak text-right',
        },
      ],
      taskTypes: ['ticket_import'],
      intervalId: null,
      lastUpdated: null,
    };
  },
  computed: {
    ...mapState('dataSource', { dataSourceDetails: 'details' }),
    ...mapState('ticket', { tickets: 'items' }),
    ...mapState('ticket', ['pagination', 'isDisabling']),
    ...mapState('fileUpload', ['shouldRefresh']),
    ...mapGetters('task', ['getTasksOfTypes']),
    runningTasks() {
      return this.getTasksOfTypes(this.taskTypes).activeTasks.filter((e) => e.status === 'pending');
    },
    tableCaption() {
      return `Last updated: ${this.lastUpdated ? this.lastUpdated.toLocaleTimeString() : '-'}`;
    },
    currentPage: {
      get() {
        return this.pagination.page;
      },
      set(val) {
        this.updatePagination({ page: val });
      },
    },
    isUploadType() {
      return this.dataSourceDetails?.type === dataSourceTypes.UPLOAD.value;
    },
    shouldRefreshTickets() {
      return this.shouldRefresh.ticket;
    },
    ticketsArray() {
      const ticketsArray = Object.values(this.tickets);
      return Object.values(ticketsArray.map((c) => objToCamel(c)));
    },
  },
  watch: {
    runningTasks(n) {
      if (n.length) {
        if (!this.intervalId) {
          this.intervalId = setInterval(this.refreshData, 5000);
        }
      } else if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
        this.refreshData();
        this.fetchFields();
        this.fetchFieldValues();
      }
    },
    shouldRefreshTickets(newVal) {
      if (newVal) {
        this.refreshData();
        this.updateShouldRefresh({ dataType: 'ticket', payload: false });
      }
    },
    currentPage() {
      this.refreshData();
    },
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  created() {
    this.refreshData();
  },
  methods: {
    ...mapActions('ticket', { fetchTickets: 'fetchItems', toggleDisabled: 'toggleDisabled' }),
    ...mapActions('field', { fetchFields: 'fetchItems' }),
    ...mapActions('fieldValue', { fetchFieldValues: 'fetchItems' }),
    ...mapMutations('ticket', ['updatePagination']),
    ...mapMutations('fileUpload', ['updateShouldRefresh']),
    openTicket(row) {
      this.$router.push({ name: 'tickets-single', params: { ticketId: row.id } });
    },
    async handleToggleDisabled(ticketId) {
      await this.toggleDisabled(ticketId);
      this.refreshData();
    },
    async refreshData() {
      await this.fetchTickets({
        params: {
          data_source: this.dataSourceDetails.id,
          page: this.pagination.page,
        },
      });
      this.lastUpdated = new Date();
    },
  },
};
</script>
<style scoped>
</style>
