import { render, staticRenderFns } from "./SectionMiniChat.vue?vue&type=template&id=ba45f28a"
import script from "./SectionMiniChat.vue?vue&type=script&lang=js"
export * from "./SectionMiniChat.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../frontend/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports