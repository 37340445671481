<template>
  <b-row
    v-if="isFetchingDetails"
    class="h-100 align-items-center"
  >
    <b-col class="text-center">
      <b-spinner
        style="width: 5rem; height: 5rem;"
      />
    </b-col>
  </b-row>
  <b-row v-else-if="objectNotFound">
    <b-col>
      <h1>Tag category not found</h1>
      <p>Requested tag category could not be found.</p>
    </b-col>
  </b-row>
  <b-card
    v-else
    class="r-75"
    body-class="p-3"
  >
    <b-row>
      <b-col class="my-auto">
        <h4 class="card-title my-auto">
          Tag category: {{ fieldDetails.displayName || fieldDetails.name }}
        </h4>
      </b-col>
      <b-col cols="auto">
        <b-button
          variant="primary"
          @click="$router.go(-1)"
        >
          Return
        </b-button>
      </b-col>
    </b-row>
    <display-key-value
      class="my-3"
      key-prop="Tag ID"
      :value-prop="fieldDetails.id"
      :min-key-width="keyWidth"
    />

    <display-key-value
      class="my-3"
      key-prop="Data Source"
      :value-prop="dataSourceIdToName(fieldDetails.dataSource)"
      :min-key-width="keyWidth"
    />
    <display-key-value
      class="my-3"
      key-prop="Data Type"
      :value-prop="fieldDetails.dataType"
      :min-key-width="keyWidth"
    />
    <display-key-value
      class="my-3"
      key-prop="Name"
      :value-prop="fieldDetails.name"
      :min-key-width="keyWidth"
    />
    <display-key-value
      class="my-3"
      key-prop="Display Name"
      :value-prop="fieldDetails.displayName"
      :min-key-width="keyWidth"
    />
    <b-row>
      <b-col>
        <h4 class="card-title my-4">
          Associated Tags
        </h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <table-data
          class="mb-0"
          :busy="isFetchingFieldValues"
          show-empty
          :fields="fieldValueFields"
          :items="fieldValueItems"
          @delete="deleteFieldValueLocal"
          @row-clicked="openFieldValue"
          @add="$bvModal.show('add-field-value-modal')"
        />
        <b-modal
          id="add-field-value-modal"
          title="Add Tag"
          centered
          :ok-disabled="$v.newFieldValue.$invalid"
          @hide="resetForm"
          @ok="addFieldValueLocal"
        >
          <b-form @submit.prevent>
            <edit-key-value
              class="mb-3"
              key-prop="Value"
              :value-prop="newFieldValue.value"
              :min-key-width="keyWidth"
              type="input"
              autofocus
              :state="!$v.newFieldValue.value.$invalid"
              @touch="fillDisplayName"
              @input="(x) => newFieldValue.value = x"
            >
              <template #feedback>
                <b-form-invalid-feedback v-if="!$v.newFieldValue.value.required">
                  Value cannot be empty.
                </b-form-invalid-feedback>
                <b-form-invalid-feedback v-if="!$v.newFieldValue.value.isUnique">
                  This value is already taken.
                </b-form-invalid-feedback>
              </template>
            </edit-key-value>
            <edit-key-value
              class="mb-3"
              key-prop="Display Value"
              :value-prop="newFieldValue.displayValue"
              :min-key-width="keyWidth"
              type="input"
              @input="(x) => newFieldValue.displayValue = x"
            />
          </b-form>
        </b-modal>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import DisplayKeyValue from 'supwiz/components/DisplayKeyValue.vue';
import TableData from 'supwiz/components/TableData.vue';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import EditKeyValue from 'supwiz/components/EditKeyValue.vue';

export default {
  name: 'FieldsSingle',
  components: {
    DisplayKeyValue,
    TableData,
    EditKeyValue,
  },
  mixins: [validationMixin],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.isFetchingFields) {
        vm.fetchFieldDetails({ id: to.params.fieldId });
      }
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchFieldDetails({ id: to.params.fieldId });
    next();
  },
  data() {
    return {
      newFieldValue: this.newFieldValueTemplate(),
      fieldValueFields: ['value', 'displayValue', { key: 'delete', label: '', tdClass: 'delete-column' }],
    };
  },
  computed: {
    ...mapState('field', { fieldDetails: 'details' }),
    ...mapState('field', ['isFetchingDetails']),
    ...mapGetters('fieldValue', { fieldValues: 'items' }),
    ...mapGetters('fieldValue', { isFetchingFieldValues: 'isFetching' }),
    ...mapGetters('dataSource', { dataSourceIdToName: 'itemIdToName' }),
    ...mapGetters('field', ['filteredFieldsList']),
    ...mapGetters('fieldValue', { fieldValues: 'items' }),
    keyWidth() {
      return 160;
    },
    objectNotFound() {
      return Object.keys(this.fieldDetails).length === 0;
    },
    fieldValueItems() {
      return Object.values(this.fieldValues).filter((item) => item.field === this.fieldDetails.id);
    },
  },
  destroyed() {
    this.setFieldDetails({});
  },
  methods: {
    ...mapActions('fieldValue', { deleteFieldValue: 'deleteItem' }),
    ...mapActions('fieldValue', { addFieldValue: 'addItem' }),
    ...mapActions('field', { fetchFieldDetails: 'fetchItemDetails' }),
    ...mapMutations('field', { setFieldDetails: 'setItemDetails' }),
    newFieldValueTemplate() {
      return {
        value: null,
        displayValue: null,
        field: null,
      };
    },
    resetForm() {
      this.newFieldValue = this.newFieldValueTemplate();
    },
    openFieldValue(row) {
      this.$router.push({ name: 'tag-values-single', params: { fieldValueId: row.id } });
    },
    addFieldValueLocal() {
      this.newFieldValue.field = this.fieldDetails.id;
      this.addFieldValue({ newItem: this.newFieldValue });
    },
    async deleteFieldValueLocal(data) {
      const modalText = `Are you sure that you want to delete ${data.displayValue}?`;
      const modalOptions = { okTitle: 'Delete', okVariant: 'danger' };
      if (await this.$bvModal.msgBoxConfirm(modalText, modalOptions)) {
        this.deleteFieldValue({ item: data });
      }
    },
    fillDisplayName() {
      this.newFieldValue.displayValue = this.newFieldValue.value;
    },
  },
  validations() {
    return {
      newFieldValue: {
        value: {
          required,
          isUnique(value) {
            return !Object.values(this.fieldValues).filter((e) => e.field === this.fieldDetails.id)
              .map((fieldValue) => fieldValue.value).includes(value);
          },
        },
      },
    };
  },
};
</script>
