// eslint-disable-next-line import/prefer-default-export
export const ids = {
  libraryName: 'supsearch', // if changed, customers must be notified
  scriptId: 'sw-supsearch-widget-script', // if changed, customers must be notified
  wrappingId: 'sw-supsearch-widget-wrap', // keep aligned in style tags, customers must be notified
  targetElementDataAttr: 'target-element', // if changed, customers must be notified
  functionOverwriteDataAttr: 'function-overwrite', // if changed, customers must be notified
  urlOverwriteDataAttr: 'supsearch-url', // if changed, customers must be notified
};

export const localizationObject = {
  en: {
    ai: {
      miniChat: {
        askFollowUp: 'Ask a follow-up question {counter}',
        fallbackMsg: 'I found these results which should be able to help you.',
        followUpCount: '({current} of {total})',
        sendMsg: 'Send',
        moreHelpBtn: 'Need More Help',
      },
    },
    errors: {
      noResults: 'Sorry, your search for "{SEARCH_PHRASE}" gave no results.',
      search: 'Search Error',
      unexpected: 'Something went wrong.',
    },
    feedback: {
      feedbackBtn: 'Results not helpful',
      feedbackGiven: 'Thank you for your feedback',
    },
    filters: {
      clearSelection: 'Clear Selection',
      selectAriaLabel: 'Select an option for {field}',
    },
    loading: 'Loading',
    results: {
      headerText: 'Your search for "{SEARCH_PHRASE}" gave {RESULT_COUNT} results.',
      footerText: 'Page {PAGE_CURRENT} of {PAGE_TOTAL} - {RESULT_COUNT} total results.',
      pinnedResult: 'Pinned Search Result {index}',
      result: 'Search Result {index}',
      pagination: {
        ariaLabel: 'Pagination',
        gotoPage: 'Go To Page {index}',
      },
    },
    searchInput: {
      clear: 'Clear Search',
      placeholder: 'Enter something to search...',
      search: 'Begin Search',
    },
  },
  da: {
    ai: {
      miniChat: {
        askFollowUp: 'Stil et opfølgende spørgsmål {counter}',
        fallbackMsg: 'Jeg fandt disse resultater, som formentlig kan hjælpe dig videre.',
        followUpCount: '({current} af {total})',
        sendMsg: 'Send',
        moreHelpBtn: 'Brug for mere hjælp',
      },
    },
    errors: {
      noResults: 'Desværre gav din søgning "{SEARCH_PHRASE}" ingen resultater.',
      search: 'Søgefejl',
      unexpected: 'Noget gik galt.',
    },
    feedback: {
      feedbackBtn: 'Resultater ikke nyttige',
      feedbackGiven: 'Tak for din feedback',
    },
    filters: {
      clearSelection: 'Ryd valg',
      selectAriaLabel: 'Vælg en mulighed for {field}',
    },
    loading: 'Indlæser',
    results: {
      headerText: 'Din søgning "{SEARCH_PHRASE}" gav {RESULT_COUNT} resultater.',
      footerText: 'Side {PAGE_CURRENT} af {PAGE_TOTAL} - {RESULT_COUNT} resultater.',
      pinnedResult: 'Fastgjort søgeresultat {index}',
      result: 'Søgeresultat {index}',
      pagination: {
        ariaLabel: 'Sidenummer',
        gotoPage: 'Gå til side {index}',
      },
    },
    searchInput: {
      clear: 'Ryd søgning',
      placeholder: 'Skriv her for at søge...',
      search: 'Start søgning',
    },
  },
};
