<template>
  <div>
    <b-card
      class="r-75"
      body-class="p-3"
    >
      <b-row class="mb-3">
        <b-col class="my-auto">
          <h4 class="card-title my-auto">
            Files upload
          </h4>
        </b-col>
        <b-col v-if="dataType === 'article'" cols="auto" class="my-auto">
          <b-button
            :disabled="submitArticlesDisabled"
            variant="primary"
            @click="submitUrls"
          >
            Submit Articles
          </b-button>
        </b-col>
        <b-col cols="auto">
          <b-button
            variant="primary"
            @click="uploadBtnClicked"
          >
            {{ uploadBtnText }}
          </b-button>
        </b-col>
      </b-row>

      <table-data
        id="uploaded-files-table"
        ref="uploaded-files-table"
        :fields="fields"
        :items="fileUploadsList"
        empty-text="There are currently no uploaded files."
        show-empty
        hover
        caption="Files upload logs are cleaned up periodically."
        :busy="!hasFetched"
        sort-by="id"
        sort-desc
        @delete="deleteFileUpload"
      >
        <template #cell(extractedData)="row">
          <b-form-input
            v-if="row.item.extractedData != null && row.item.dataType === 'article'"
            :key="row.item.id"
            v-model="id2currentUrl[row.item.id]"
            placeholder="Enter url"
          />
        </template>
        <template #cell(dataSource)="row">
          <b-form-input
            v-if="row.item.extractedData != null && row.item.dataType === 'article'"
            :key="row.item.id"
            v-model="id2externalId[row.item.id]"
            placeholder="Enter external id"
          />
        </template>
        <template #cell(dataType)="row">
          <b-form-input
            v-if="row.item.extractedData != null && row.item.dataType === 'article'"
            :key="row.item.id"
            v-model="id2title[row.item.id]"
            placeholder="Enter title"
          />
        </template>
      </table-data>
    </b-card>
    <articles-upload-modal />
    <chats-upload-modal />
    <tickets-upload-modal />
    <queries-upload-modal />
  </div>
</template>

<script>
import {
  mapState, mapActions, mapGetters, mapMutations,
} from 'vuex';
import TableData from 'supwiz/components/TableData.vue';
import { DataTypes } from '@/js/constants';
import { objToCamel } from '@/js/utils';
import ArticlesUploadModal from '@/components/DataSource/ArticlesUploadModal.vue';
import ChatsUploadModal from '@/components/DataSource/ChatsUploadModal.vue';
import TicketsUploadModal from '@/components/DataSource/TicketsUploadModal.vue';
import QueriesUploadModal from '@/components/DataSource/QueriesUploadModal.vue';

function statusFormatter(errorMessage, key, item) {
  if (item.extractedData != null) {
    if (item.dataType === DataTypes.ARTICLE) {
      return 'Ready - please fill in url';
    }
    return 'Done';
  }
  if (item.readyForDeletion === true) {
    return 'Done';
  }
  if (item.errorMessage != null) {
    return `Error: ${item.errorMessage}`;
  }
  if (item.task != null) {
    return 'Processing';
  }
  return 'Pending';
}

export default {
  name: 'FileUploadTable',
  components: {
    TableData, ArticlesUploadModal, ChatsUploadModal, TicketsUploadModal, QueriesUploadModal,
  },
  props: {
    dataType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'delete', label: '',
        },
        {
          key: 'id', tdClass: 'table-nobreak',
        },
        {
          key: 'name',
        },
        {
          key: 'errorMessage', label: 'Status', formatter: statusFormatter,
        },
        {
          key: 'extractedData', label: 'Current Url',
        },
        {
          key: 'dataSource', label: 'External Id',
        },
        {
          key: 'dataType', label: 'Title',
        },
      ],
      intervalId: null,
      id2currentUrl: {},
      id2externalId: {},
      id2title: {},
      fetched: false,
      oldKeys: '',
    };
  },
  computed: {
    ...mapGetters('fileUpload', { fileUploads: 'items' }),
    ...mapState('fileUpload', ['shouldRefresh']),
    ...mapState('dataSource', { dataSourceDetails: 'details' }),
    uploadBtnText() {
      return this.dataType === 'query' ? 'Upload queries' : `Upload ${this.dataType}s`;
    },
    fileUploadsList() {
      if (this.fileUploads) {
        return Object.values(this.fileUploads).filter(
          (x) => x.dataType === this.dataType,
        ).map(
          (c) => objToCamel(c),
        );
      }
      return [];
    },
    submitArticlesDisabled() {
      for (const fileId of Object.keys(this.fileUploads)) {
        if (this.id2currentUrl[fileId]?.length) {
          return false;
        }
      }
      return true;
    },
    hasFetched() {
      return this.fetched;
    },
    shouldRefreshFileUpload() {
      return this.shouldRefresh.fileUpload;
    },
  },
  watch: {
    shouldRefreshFileUpload(newVal) {
      if (newVal) {
        this.refresh();
        this.updateShouldRefresh({ dataType: 'fileUpload', payload: false });
      }
    },
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  created() {
    this.refresh();
    this.initInterval();
  },
  methods: {
    ...mapActions('fileUpload', { fetchUploadedFiles: 'fetchItems', convertToArticles: 'convertToArticles', deleteItem: 'deleteItem' }),
    ...mapMutations('fileUpload', ['updateShouldRefresh']),
    uploadBtnClicked() {
      switch (this.dataType) {
        case 'article': this.$bvModal.show('upload-articles-modal'); break;
        case 'chat': this.$bvModal.show('upload-chats-modal'); break;
        case 'ticket': this.$bvModal.show('upload-tickets-modal'); break;
        case 'query': this.$bvModal.show('upload-queries-modal'); break;
        default: break;
      }
    },
    initInterval() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      this.intervalId = setInterval(this.refresh, 5000);
    },
    refresh() {
      if (this.dataSourceDetails.id) {
        this.fetchUploadedFiles({
          params: {
            data_source: this.dataSourceDetails.id,
          },
        }).then(() => {
          for (const fileId of Object.keys(this.fileUploads)) {
            if (this.id2externalId[fileId] == null) {
              this.id2externalId[fileId] = this.fileUploads[fileId].name;
            }
            if (this.id2title[fileId] == null && this.fileUploads[fileId].extractedData != null) {
              this.id2title[fileId] = this.fileUploads[fileId].extractedData.title;
            }
          }
          this.fetched = true;
        });
      }
    },
    async submitUrls() {
      const id2data = {};
      for (const fileId of Object.keys(this.fileUploads)) {
        const tmpData = {
          current_url: this.id2currentUrl[fileId],
          external_id: this.id2externalId[fileId],
          title: this.id2title[fileId],
        };
        if (tmpData.current_url != null && tmpData.external_id != null && tmpData.title != null) {
          id2data[fileId] = tmpData;
        }
      }
      const success = await this.convertToArticles({ id2data });
      if (success) {
        this.$root.$emit('refresh-articles');
      }
    },
    async deleteFileUpload(item) {
      this.deleteItem({ item, fetchParams: { data_source: this.dataSourceDetails.id } });
    },
  },
};
</script>
