import { library } from '@fortawesome/fontawesome-svg-core';
// Import the icons we use
// We do this to decrease the bundle-size
import {
  faAngleDown, faAngleUp, faArrowAltCircleRight, faBars, faBell, faBook, faClipboard,
  faClone,
  faCode, faCodeBranch, faCog, faComment, faComments, faEdit, faEllipsisV, faExchangeAlt,
  faExclamation, faFolderOpen, faHammer, faHome, faPlus, faPollH, faQuestionCircle, faRunning,
  faSearch, faTags, faTicketAlt, faTimes, faTrashAlt, faGripVertical, faEye,
  faCopy, faCogs, faInfo, faEyeSlash, faCheck, faChartColumn, faFilter, faTable,
  faChartPie, faChartLine, faUserSecret, faArrowsAlt, faInfoCircle, faSlidersH, faLessThanEqual,
  faWrench, faPencil, faClipboardList, faVial, faDownload, faMapPin, faArrowLeft, faWindowMaximize,
  faAngleRight, faCircleXmark, faCircleCheck, faArrowUpRightFromSquare, faMinus, faLink, faArrowUp,
  faArrowDown, faCaretDown, faCircleExclamation, faTableList, faUser,
  faRocket, faAnglesDown, faDirections, faClock, faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faClipboard,
  faClone,
  faCode,
  faMagnifyingGlass,
  faTableList,
  faWindowMaximize,
  faArrowUpRightFromSquare,
  faFilter,
  faHome,
  faPollH,
  faChartLine,
  faDirections,
  faClock,
  faClipboardList,
  faPencil,
  faLink,
  faAngleRight,
  faSearch,
  faAnglesDown,
  faChartPie,
  faCogs,
  faEye,
  faMinus,
  faEyeSlash,
  faAngleUp,
  faVial,
  faCopy,
  faInfoCircle,
  faSlidersH,
  faTable,
  faArrowsAlt,
  faAngleDown,
  faTrashAlt,
  faPlus,
  faRunning,
  faExclamation,
  faQuestionCircle,
  faComment,
  faCircleXmark,
  faCircleCheck,
  faCog,
  faTimes,
  faInfo,
  faEllipsisV,
  faComments,
  faBook,
  faRocket,
  faFolderOpen,
  faExchangeAlt,
  faEdit,
  faTags,
  faCodeBranch,
  faBell,
  faTicketAlt,
  faArrowAltCircleRight,
  faHammer,
  faCircleExclamation,
  faBars,
  faGripVertical,
  faCheck,
  faChartColumn,
  faUserSecret,
  faUser,
  faLessThanEqual,
  faWrench,
  faDownload,
  faMapPin,
  faArrowLeft,
  faArrowUp,
  faArrowDown,
  faCaretDown,
);

export default library;
